import React, { memo, useEffect, useRef, useState } from 'react';

import { useMediaQuery } from '@material-ui/core';
import { Button } from '@tecma/ds';
import { useTranslation } from '@tecma/i18n';
import { useHistory } from 'react-router-dom';

import { PageHeaderActions } from '@v2/@module-clients/components/Page/Actions';
import ClientInput from 'models/ClientInput';
import { clientAddRoute } from 'routes/Routes';
import { useProjectID } from 'store/hooks/useProjectID';
import { useStore } from 'store/storeUtils';

import { Banner } from 'components/common/@v2/Banner';

import { ADMIN, FRONT_OFFICE, PROPRIETA, VENDOR, VENDOR_MANAGER } from 'constants/PagePermission';

import { ModuleClients } from '../../../@v2/@module-clients';
import { ButtonSwitchVersion } from '../../../components/common/@v2/ButtonSwitchVersion';
import { useModuleClients } from '../../../customHooks/useModuleClients';

export const V2_PageClienti = memo(() => {
  const projectId = useProjectID();
  const { t } = useTranslation();
  const history = useHistory();
  const store = useStore() as any;
  const bannerRef = useRef(null);
  const [bannerHeight, setBannerHeight] = useState(0);
  const { showBanner, onClickLegacy, onSwitch, onDismiss } = useModuleClients('v2');
  const matchesMD = useMediaQuery('(max-width:959px)', { noSsr: true });
  const showButton =
    store.loggedUser?.role === VENDOR ||
    store.loggedUser?.role === VENDOR_MANAGER ||
    store.loggedUser?.role === FRONT_OFFICE ||
    store.loggedUser?.role === ADMIN;

  const showToolbaer = store.loggedUser?.role !== PROPRIETA;

  const newClient = () => {
    store.updateClientToAdd({ ...ClientInput.createClientInputFromStore(null, []), notificationLanguage: store.defaultLanguage });
    store.setRealLocation(clientAddRoute.to);
    history.push(clientAddRoute.to);
  };

  const updateBannerHeight = () => {
    if (bannerRef.current) {
      // @ts-ignore
      const height = bannerRef.current.getBoundingClientRect().height;
      if (!bannerHeight || bannerHeight !== height) {
        setBannerHeight(height);
      }
    } else if (bannerHeight) {
      setBannerHeight(0);
    }
  };

  useEffect(() => {
    updateBannerHeight();
  }, [bannerHeight, showBanner]);

  useEffect(() => {
    window.addEventListener('resize', updateBannerHeight);
    return () => {
      window.removeEventListener('resize', updateBannerHeight);
    };
  }, []);

  return (
    <>
      <PageHeaderActions height={bannerHeight ?? 0}>
        {showButton && (
          <Button className='add-client-button add-client-button-v2' onClick={newClient} iconName='plus'>
            {!matchesMD && t('client.insertNewClient')}
          </Button>
        )}
        {!showBanner && <ButtonSwitchVersion onClick={() => onSwitch('legacy')} label={t('clients.banner.newInterface.buttonLabel')} />}
      </PageHeaderActions>
      {showBanner && (
        <Banner
          ref={bannerRef}
          onSwitch={onClickLegacy}
          onDismiss={onDismiss}
          title={t('clients.banner.newInterface.title')}
          description={t('clients.banner.newInterface.description')}
          buttonLabel={t('clients.banner.newInterface.buttonLabel')}
        />
      )}
      {/* @ts-ignore */}
      <ModuleClients style project_id={projectId} languages={store.configLanguages} showToolbar={showToolbaer} />
    </>
  );
});
