import React from 'react';

import { autorun } from 'mobx';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import useLogout from 'customHooks/useLogout';
import { HomeRoute } from 'routes/Routes';

import CrmLoader from 'components/common/CrmLoader';

import { useStore } from '../store/storeUtils';

const ClientComponent = observer(() => {
  const store = useStore();
  const history = useHistory();
  const { logOut } = useLogout();

  React.useEffect(() =>
    autorun(async () => {
      const isLoggedIn = store.projectId && store.loggedUser;
      if (isLoggedIn) {
        // l'utente è loggato, lo riporto alla pagina richiesta
        const params = new URLSearchParams(window.location.search);
        params.delete('hostname');
        history.push(`${HomeRoute.to}?${params}`);
      } else if (!store.checkingJwt) {
        // Se non esiste il JWT l'utente non è loggato e lo rimando alla login
        // Creo l'url di reindirizzamento in base all'ambiente in cui ci troviamo
        // TODO sarebbe meglio avere un identificativo piuttosto che usare il nome...
        // @ts-ignore
        const targetTool = store?.enabledTools?.find((e) => e.name === 'BusinessPlatform');
        let firstPart;
        let secondPart;
        let finalTargetTool = '';

        if (targetTool) {
          const targetToolSplitted = targetTool.url.split('?');

          //se la prima parte non contiene /login
          if (!targetToolSplitted[0].includes('/login')) {
            //aggiungo al fondo /login
            if (targetToolSplitted[0].endsWith('/')) {
              firstPart = targetToolSplitted[0].concat('login');
            } else {
              firstPart = targetToolSplitted[0].concat('/login');
            }
          } else {
            firstPart = targetToolSplitted[0];
          }

          //costruisco il backTo
          const backtoUrl = new URL(window.location.href);
          const params = new URLSearchParams(backtoUrl.search);
          params.delete('hostname');
          let backTo = `${window.location.origin}${window.location.pathname}?${params}`;
          backTo = backTo.replace('/login?', '');

          //se la seconda parte esiste
          if (targetToolSplitted[1]) {
            secondPart = targetToolSplitted[1].concat(`&backTo=${encodeURIComponent(backTo)}`);
            finalTargetTool = firstPart + '?' + secondPart;
          } else {
            finalTargetTool = firstPart + '?' + `backTo=${encodeURIComponent(backTo)}`;
          }
          try {
            await logOut();
            window.location.href = finalTargetTool;
          } catch (e) {
            console.error(e);
            window.location.href = finalTargetTool;
          }
        }
      }
      // @ts-ignore
    }, [store.projectId, store.loggedUser, store.checkingJwt]),
  );

  return (
    <>
      <CrmLoader hasBackdrop={false} loading={true} z={true} />
    </>
  );
});

export default ClientComponent;
