import React from 'react';

import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import { LanguageSelector } from '@tecma/ds';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { useStore } from 'store/storeUtils';

import CrmAvatar from 'components/common/CrmAvatar';
import CrmSvgLoader from 'components/common/CrmSvgLoader';

import LogoTecma from 'images/assets/logo_TECMA_white.svg';

import 'styles/header.scss';
/**
 * Page Header
 * based on material-ui BreadCrumbs
 * Unknown props are simply ignored
 * @component
 * @see {@link https://material-ui.com/api/breadcrumbs/  BreadCrumbs API}
 */
const CrmHeader = observer((props) => {
  const { setLanguage } = props;

  const store = useStore();

  const openDrawer = () => {
    store.setOpenDrawer(!store.openDrawer);
  };

  const handleChangeLanguage = (selectedLanguage) => {
    const trueLanguage = selectedLanguage.split('-')[0];
    const locale = selectedLanguage.split('-')[1];
    setLanguage(trueLanguage, locale);
  };

  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });

  return (
    <div className='header-wrapper'>
      <div className='tecma-elements'>
        <div className='image-tecma-wrapper'>
          <CrmSvgLoader data={LogoTecma} alt='Tecma' className='image-tecma' />
        </div>
        {!matchesPhone && <div className='crm-wrapper'>FOLLOW UP</div>}
      </div>
      <div className='desktop-elements'>
        <CrmAvatar />
        {!matchesPhone ? (
          <LanguageSelector
            languages={store.configLanguages}
            currentLanguage={store.getCurrentLanguage()}
            onChangeLanguage={handleChangeLanguage}
            fixed={true}
          />
        ) : (
          <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' onClick={openDrawer} className='mobile-drawer'>
            <MenuIcon />
          </IconButton>
        )}
      </div>
    </div>
  );
});

CrmHeader.propTypes = {
  breadcrumb: PropTypes.string,
  /** title: page title */
  title: PropTypes.string,
  /** goBack: boolean. If true, current page is a subPath. TODO: change this prop name */
  goBack: PropTypes.bool,
  setLanguage: PropTypes.func,
};

CrmHeader.defaultProps = {
  title: '',
  goBack: false,
};

export default CrmHeader;
