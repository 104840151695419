import React, { memo } from 'react';

import { useTranslation } from '@tecma/i18n';

import { PageWrapper } from './Wrapper';
import { Table } from '../Table/Table.Component';
import { Toolbar } from '../Toolbar/Toolbar.Component';

import './theme.scss';

interface Props {
  project_id: string;
  showToolbar: boolean;
}

export const PageClients = memo(({ project_id, showToolbar }: Props) => {
  const { t } = useTranslation();

  if (sessionStorage.getItem('projectId')) {
    if (sessionStorage.getItem('projectId') !== project_id) {
      sessionStorage.removeItem('searchText');
      sessionStorage.setItem('projectId', project_id);
    }
  } else {
    sessionStorage.setItem('projectId', project_id);
  }

  return (
    <PageWrapper className='clients-page-wrapper'>
      {/* <PageHeader className="clients-page-header">
        <p className="clients-page-title">{t('header.title')}</p>
        <PageHeaderActions className="page-header-actions">
          <button />
        </PageHeaderActions>
      </PageHeader> */}
      <h4 className='clients-page-description'>{t('pageDescription')}</h4>
      {showToolbar && <Toolbar defaultValue={sessionStorage.getItem('searchText') || ''} project_id={project_id} />}
      <Table project_id={project_id} />
    </PageWrapper>
  );
});
