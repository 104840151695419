import React, { ReactNode, useEffect } from 'react';

import { TLocale } from './i18n.declarations';
import { loadI18n } from './i18n.helpers';

interface Props {
  children: ReactNode;
  project_id: string;
  languages: Array<TLocale>;
  translations: Record<string, unknown>;
}

export const ProviderI18n = ({ children, project_id, languages, translations }: Props) => {
  const loadTranslations = async () => {
    await loadI18n({
      projectId: project_id,
      languages,
      translations,
      moduleName: 'clients',
    });
  };

  useEffect(() => {
    loadTranslations();
  }, []);

  return <>{children}</>;
};
