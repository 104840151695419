import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';

const API = (store) => {
  const errorLink = onError(({ networkError, graphQLErrors, operation, response }) => {
    // @ts-ignore
    if (
      // @ts-ignore
      networkError?.result?.errors[0]?.extensions?.code === 'UNAUTHENTICATED' ||
      // @ts-ignore
      networkError?.statusCode === 401 ||
      graphQLErrors?.[0]?.extensions?.response?.status === 403
    ) {
      // @ts-ignore
      console.log(`Apollo client network error code: ${networkError?.statusCode}`);
      store.forceLogout(true); // prima passa per il componente "LogoutListener" per la pulizia dei cookies e poi avverrà il reindirizzamento
      // @ts-ignore
    } else if (operation?.operationName === 'getUserByJWT' && response?.data?.getUserByJWT === null && response?.errors?.length > 0) {
      console.log(`getUserByJWT: user not found`);
      store.forceLogout(true); // prima passa per il componente "LogoutListener" per la pulizia dei cookies e poi avverrà il reindirizzamento
    }
  });

  const httpLink = from([
    errorLink,
    new HttpLink({
      credentials: 'include',
      uri: process.env.REACT_APP_APOLLO_URI,
    }),
  ]);

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
      },
    };
  });

  return new ApolloClient({
    link: authLink.concat(httpLink),
    credentials: 'include',
    cache: new InMemoryCache({
      addTypename: false,
      // typePolicies: {
      //   Query: {
      //     fields: {
      //       getAllBuildings: {
      //         keyArgs: false,
      //         merge(existing = [], incoming) {
      //           return [...incoming];
      //         },
      //       },
      //     },
      //   },
      // },
    }),
  });
};

export default API;
