import React from 'react';

import { ReactSVG } from 'react-svg';

interface Props {
  data?: string;
  className?: string;
  alt?: string;
  style?: object;
}

const CrmSvgLoader = ({ data, className = '', alt = 'image', style = {}, ...rest }: Props) => {
  if (data) return <ReactSVG style={style} alt={alt} src={data} className={className} {...rest} />;
  return null;
};

export default CrmSvgLoader;
