let envConfig = {
  BUCKET_BASEURL: '',
  API_URI: '',
  APOLLO_URI: '',
  token: '',
};

export const setConfig = (config: typeof envConfig) => {
  envConfig = { ...envConfig, ...config };
};

export const getConfig = () => envConfig;
