import React from 'react';

import { useMutation } from '@apollo/client';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useTranslation } from '@tecma/i18n';
import { observer } from 'mobx-react';
import queryString from 'query-string';

import Client from 'client/Client';
import { useStore } from 'store/storeUtils';

import CrmButton from 'components/common/CrmButton';
import CrmInput from 'components/common/CrmInput';
import CrmSnackbarError from 'components/common/CrmSnackbarError';
import CrmSnackbarFeedback from 'components/common/CrmSnackbarFeedback';
import CriteriaPasswordRow from 'components/specific/CriteriaPasswordRow';

import 'styles/login.scss';

const AggiornaConfermaPassword = observer((props) => {
  const store = useStore();
  const theme = useTheme();
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const { t } = useTranslation();

  const UrlQueryStrings = props.location.search;
  const queryValues = queryString.parse(UrlQueryStrings);

  const [secretToken] = React.useState(queryValues.token);
  const [password, setPassword] = React.useState('');
  const [error] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState('');
  const [confirm, setConfirm] = React.useState('');
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  //++++++++++++++++++++++++++++++++++++++++++++
  const [capitalPresent, setCapitalPresent] = React.useState(false);
  const [lowerPresent, setLowerPresent] = React.useState(false);
  const [checkSeven, setCheckSeven] = React.useState(false);
  const [checkNumberPresent, setCheckNumberPresent] = React.useState(false);
  const [checkSpecialPresent, setCheckSpecialPresent] = React.useState(false);

  const [capitalError, setCapitalError] = React.useState(false);
  const [lowerError, setLowerError] = React.useState(false);
  const [sevenError, setSevenError] = React.useState(false);
  const [numberError, setNumberError] = React.useState(false);
  const [specialError, setSpecialError] = React.useState(false);

  const [errorFirstName, setErrorFirstName] = React.useState(false);
  const [errorThreePassword, setErrorThreePassword] = React.useState(false);

  //++++++++++++++++++++++++++++++++++++++++++++

  const [confirmUser] = useMutation(Client.CONFIRM_PASSWORD_USER);

  const redirectTo = () => {
    // TODO sarebbe meglio avere un identificativo piuttosto che usare il nome...
    const targetTool = store?.enabledTools?.find((e) => e.name === 'BusinessPlatform');
    if (targetTool) {
      window.location.href = targetTool.url;
    }
  };

  const resultCall = (res) => {
    switch (res.data.confirmUserAndChangePasswordBP) {
      case 'success': {
        store.setSnackbar(true, t('resetPassword.snackbar.succes'));
        redirectTo();
        break;
      }
      case 'expiredToken': {
        store.setSnackbarError(true, t('resetPassword.snackbar.exipred'));
        redirectTo();
        break;
      }
      case 'wrongPassword':
      case 'invalidToken': {
        store.setSnackbarError(true, t('resetPassword.snackbar.invalid'));
        break;
      }
      case 'passwordEqualsEmail': {
        store.setSnackbarError(true, t('resetPassword.snackbar.passwordEqualsEmail'));
        setErrorFirstName(true);
        break;
      }
      case 'passwordEqualsName': {
        setErrorFirstName(true);
        store.setSnackbarError(true, t('resetPassword.snackbar.passwordEqualsName'));
        break;
      }
      case 'notSecurePassword': {
        store.setSnackbarError(true, t('resetPassword.snackbar.notSecurePassword'));
        break;
      }
      case 'passwordAlreadyUsed': {
        setErrorThreePassword(true);
        store.setSnackbarError(true, t('resetPassword.snackbar.passwordAlreadyUsed'));
        break;
      }
      default: {
        store.setSnackbarError(true, t('resetPassword.snackbar.error'));
        break;
      }
    }
  };

  React.useEffect(() => {
    if (store.loggedUser) {
      store.logOut();
    }
  }, []);

  const addDefaultSrc = (ev, url) => {
    if (ev.target.src.includes(process.env.REACT_APP_BUCKET_BASEURL)) {
      ev.target.src = url && url.length > 0 && url[0];
    } else {
      ev.target.hidden = true;
    }
  };

  //++++++++++++++++++++++++++++++++++++++++++++++++++
  const checkNonAlphaNumeric = (psw) => {
    const nonAlphaRegex = /[^a-zA-Z0-9\s:]/;
    if (psw.match(nonAlphaRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const checkNumber = (psw) => {
    let numberFlag = false;
    const numberRegex = /\d/;
    if (psw.match(numberRegex)) {
      numberFlag = true;
    }
    return numberFlag;
  };

  const checkCapital = (psw) => {
    let capitalFlag = false;
    const capitalRegex = /[A-Z]/;
    if (psw.match(capitalRegex)) {
      capitalFlag = true;
    }

    return capitalFlag;
  };

  const checkLowerCase = (psw) => {
    let normalFleg = false;
    const normalRegex = /[a-z]/;
    if (psw.match(normalRegex)) {
      normalFleg = true;
    }
    return normalFleg;
  };

  const checkPasswordLength = (psw) => {
    if (psw.length > 6 && psw.length <= 20) {
      return true;
    } else {
      return false;
    }
  };

  const handleChangePsw = (psw) => {
    setNewPassword(psw);
    setCapitalError(false);
    setLowerError(false);
    setSevenError(false);
    setNumberError(false);
    setSpecialError(false);
    setErrorFirstName(false);
    setErrorThreePassword(false);

    if (checkPasswordLength(psw)) {
      setCheckSeven(true);
    } else {
      setCheckSeven(false);
    }
    if (checkCapital(psw)) {
      setCapitalPresent(true);
    } else {
      setCapitalPresent(false);
    }
    if (checkLowerCase(psw)) {
      setLowerPresent(true);
    } else {
      setLowerPresent(false);
    }

    if (checkNonAlphaNumeric(psw)) {
      setCheckSpecialPresent(true);
    } else {
      setCheckSpecialPresent(false);
    }
    if (checkNumber(psw)) {
      setCheckNumberPresent(true);
    } else {
      setCheckNumberPresent(false);
    }
  };

  //++++++++++++++++++++++++++++++++++++++++++++++++

  return (
    <>
      <CrmSnackbarFeedback />
      <CrmSnackbarError />
      <Grid container>
        <Hidden xsDown>
          <Grid item md={6}>
            {store.loginProjectName && (
              <img
                alt='login'
                className='left-half-login'
                src={`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${store.loginProjectName}/global/img/login.jpg`}
                onError={(ev) => addDefaultSrc(ev, store.assetLogin)}
              />
            )}
          </Grid>
        </Hidden>
        <Grid item xs={12} md={6}>
          <div className='right-half-login'>
            {store.loginProjectName && (
              <img
                className='login-icon'
                alt='logo'
                src={`${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${store.loginProjectName}/global/img/primary-logo.svg`}
                style={{ marginBottom: '40px' }}
                onError={(ev) => addDefaultSrc(ev, store.logo)}
              />
            )}
            <div className='login-hint'>{t('resetPassword.oldPassword')}</div>
            <CrmInput
              variant='standard'
              label={t('resetPassword.label.oldPassword')}
              type={!showOldPassword ? 'password' : 'text'}
              extraClass='login-margin-bottom'
              defaultInput
              value={password}
              style={
                !matchesPhone
                  ? { width: '100%', maxWidth: '350px', marginBottom: '20px' }
                  : { width: '100%', maxWidth: '300px', marginBottom: '20px' }
              }
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => {
                        setShowOldPassword(!showOldPassword);
                      }}
                    >
                      {showOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className='login-hint'>{t('resetPassword.newPassword')}</div>

            <CrmInput
              variant='standard'
              label={t('resetPassword.label.newPassword')}
              type={!showPassword ? 'password' : 'text'}
              extraClass='login-margin-bottom'
              defaultInput
              style={
                !matchesPhone
                  ? { width: '100%', maxWidth: '350px', marginBottom: '20px' }
                  : { width: '100%', maxWidth: '300px', marginBottom: '20px' }
              }
              value={newPassword}
              error={newPassword !== '' && error}
              helperText={newPassword !== '' && error ? t('resetPassword.error.newPassword') : ''}
              onChange={(e) => {
                handleChangePsw(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => {
                        setShowPassword(!showPassword);
                      }}
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <CrmInput
              variant='standard'
              label={t('resetPassword.label.confirmPassword')}
              type={!showConfirmPassword ? 'password' : 'text'}
              extraClass='login-margin-bottom'
              defaultInput
              style={
                !matchesPhone
                  ? { width: '100%', maxWidth: '350px', marginBottom: '20px' }
                  : { width: '100%', maxWidth: '300px', marginBottom: '20px' }
              }
              value={confirm}
              error={confirm !== '' && confirm !== newPassword}
              helperText={confirm !== '' && confirm !== newPassword ? t('resetPassword.error.confirmPassword') : ''}
              onChange={(e) => {
                setConfirm(e.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={() => {
                        setShowConfirmPassword(!showConfirmPassword);
                      }}
                    >
                      {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <div className='login-hint'>{t('resetPassword.passwordMust')}</div>
            <div style={!matchesPhone ? { width: '100%', maxWidth: '350px' } : { width: '100%', maxWidth: '300px' }}>
              <CriteriaPasswordRow
                check={capitalPresent}
                errorCriteria={capitalError}
                criteriaLabel={t('resetPassword.uppercaseCriteria')}
              />
              <CriteriaPasswordRow check={lowerPresent} errorCriteria={lowerError} criteriaLabel={t('resetPassword.lowercaseCriteria')} />
              <CriteriaPasswordRow
                check={checkNumberPresent}
                errorCriteria={numberError}
                criteriaLabel={t('resetPassowrd.numberCriteria')}
              />
              <CriteriaPasswordRow
                check={checkSpecialPresent}
                errorCriteria={specialError}
                criteriaLabel={t('resetPassword.symbolCriteria')}
              />
              <CriteriaPasswordRow
                check={checkSeven}
                errorCriteria={sevenError}
                criteriaLabel={t('resetPassword.sevenCharacterCriteria')}
              />
              {errorFirstName && <CriteriaPasswordRow check={false} errorCriteria={true} criteriaLabel={t('resetPassword.nameCriteria')} />}
              {errorThreePassword && (
                <CriteriaPasswordRow check={false} errorCriteria={true} criteriaLabel={t('resetPassword.differentFromLastThreeCriteria')} />
              )}
            </div>
            <CrmButton
              id='login-button'
              className='login-button-input'
              disabled={error}
              onClick={() => {
                let countError = 0;
                if (!checkPasswordLength(newPassword)) {
                  setSevenError(true);
                  countError = countError + 1;
                } else {
                  setCheckSeven(true);
                  setSevenError(false);
                }

                if (!checkLowerCase(newPassword)) {
                  setLowerError(true);
                  countError = countError + 1;
                } else {
                  setLowerPresent(true);
                  setLowerError(false);
                }

                if (!checkCapital(newPassword)) {
                  setCapitalError(true);
                  countError = countError + 1;
                } else {
                  setCapitalPresent(true);
                  setCapitalError(false);
                }

                if (!checkNumber(newPassword)) {
                  countError = countError + 1;
                  setNumberError(true);
                } else {
                  setCheckNumberPresent(true);
                  setNumberError(false);
                }

                if (!checkNonAlphaNumeric(newPassword)) {
                  countError = countError + 1;
                  setSpecialError(true);
                } else {
                  setCheckSpecialPresent(true);
                  setSpecialError(false);
                }
                if (countError === 0 && newPassword === confirm) {
                  confirmUser(Client.CONFIRM_PASSWORD_USER_DEFAULT_OPTIONS(store.project_id, secretToken, password, newPassword))
                    .then((res) => {
                      resultCall(res);
                    })
                    .catch(() => {
                      store.setSnackbarError(true, t('resetPassword.snackbar.error'));
                    });
                }
              }}
            >
              {t('resetPassword.access')}
            </CrmButton>
          </div>
        </Grid>
      </Grid>
    </>
  );
});

export default AggiornaConfermaPassword;
