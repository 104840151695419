import { createSelector } from '@reduxjs/toolkit';

import { TStore } from './hooks';

const selectorApp = (store: TStore) => store.app;

export const selectorClients = createSelector(selectorApp, (app) => (app.clients ? JSON.parse(JSON.stringify(app.clients)) : []));
export const selectorLoading = createSelector(selectorApp, (app) => !!app.loading);
export const selectorProjectId = createSelector(selectorApp, (app) => app.projectId || null);
export const selectorFilterPage = createSelector(selectorApp, (app) => app.filters?.page || 1);
export const selectorFilterPerPage = createSelector(selectorApp, (app) => app.filters?.perPage || 10);
export const selectorFilterPrevPage = createSelector(selectorApp, (app) => app.filters?.prevPage);
export const selectorFilterNextPage = createSelector(selectorApp, (app) => app.filters?.nextPage);
export const selectorFilterTotalDocs = createSelector(selectorApp, (app) => app.filters?.totalDocs || 0);
export const selectorFilterTotalPages = createSelector(selectorApp, (app) => app.filters?.totalPages || 0);
export const selectorSortDirection = createSelector(selectorApp, (app) => app.sortOrder);
export const selectorSortField = createSelector(selectorApp, (app) => app.sortField);
export const selectorSelectedTab = createSelector(selectorApp, (app) => app.selectedTab);
