import { createAsyncThunk } from '@reduxjs/toolkit';

import { createClientGraphQL } from '../../graphql/createClientGraphQL';
import { ParamsOnSearch } from '../declarations/common';
import {
  ResponseQueryClientList,
  ResponseQueryClientListMyHome,
  QUERY_CLIENT_LIST,
  QUERY_CLIENT_LIST_MYHOME,
  ResponseQueryClientListCRM,
  QUERY_CLIENT_LIST_CRM,
} from '../queries/list';

export const thunkClientList = createAsyncThunk<
  ResponseQueryClientList['getCustomersTabInfo'],
  { project_id: string; params: ParamsOnSearch }
>('client/list', async ({ project_id, params }, thunkAPI) => {
  try {
    const GraphQL = createClientGraphQL();
    const response = await GraphQL.query({ query: QUERY_CLIENT_LIST, variables: { project_id, params } });
    const { getCustomersTabInfo } = response.data as ResponseQueryClientList;
    return thunkAPI.fulfillWithValue(getCustomersTabInfo);
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue('Failed to load client list');
  }
});

export const thunkClientListMyHome = createAsyncThunk<
  ResponseQueryClientListMyHome['getMyHomeTabInfo'],
  { project_id: string; params: ParamsOnSearch }
>('clientMyHome/list', async ({ project_id, params }, thunkAPI) => {
  try {
    const GraphQL = createClientGraphQL();
    const response = await GraphQL.query({ query: QUERY_CLIENT_LIST_MYHOME, variables: { project_id, params } });
    const { getMyHomeTabInfo } = response.data as ResponseQueryClientListMyHome;
    return thunkAPI.fulfillWithValue(getMyHomeTabInfo);
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue('Failed to load MyHome client list');
  }
});

export const thunkClientListCRM = createAsyncThunk<
  ResponseQueryClientListCRM['getCRMTabInfo'],
  { project_id: string; params: ParamsOnSearch }
>('clientCRM/list', async ({ project_id, params }, thunkAPI) => {
  try {
    const GraphQL = createClientGraphQL();
    const response = await GraphQL.query({ query: QUERY_CLIENT_LIST_CRM, variables: { project_id, params } });
    const { getCRMTabInfo } = response.data as ResponseQueryClientListCRM;
    return thunkAPI.fulfillWithValue(getCRMTabInfo);
  } catch (error) {
    console.error(error);
    return thunkAPI.rejectWithValue('Failed to load CRM client list');
  }
});
