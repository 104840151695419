import React from 'react';

import { InputAdornment, Tooltip } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { Trans, useTranslation } from '@tecma/i18n';
import clsx from 'clsx';

import CrmSvgLoader from 'components/common/CrmSvgLoader';
import IconWithTooltip from 'components/specific/IconWithTooltip';

import { defaultInputProps, defaultInputLabelProps } from 'constants/InputConstants';

import 'styles/input.scss';

const TRANSLATIONS_TAGS_MAPPING = { b: <b />, br: <br /> };

interface Props {
  defaultInput: boolean;
  extraClass: string;
  label: string;
  extraInfo: string;
  required: boolean;
  disabled: boolean;
  variant: 'standard' | 'filled' | 'outlined';
  value: string | number;
  extraInputLabelProps: Record<string, string>;
  extraInputProps: Record<string, any>;
  defaultValue: string;
  minLength: string;
  maxLength: string;
  min: string;
  max: string;
  error: boolean;
  valueIcon: string;
  valueIconTooltipLabel: string;
  valueIconTooltipValues: string;
  tooltip: string;
}

const CrmInput = ({
  defaultInput = false,
  extraClass = '',
  label = '',
  extraInfo,
  disabled = false,
  variant = 'standard',
  value,
  extraInputLabelProps = {},
  extraInputProps = {
    classes: {},
  },
  defaultValue,
  minLength,
  maxLength,
  min,
  max,
  error,
  valueIcon,
  valueIconTooltipLabel,
  valueIconTooltipValues,
  tooltip,
  required = false,
  ...rest
}: Props) => {
  const { t } = useTranslation();

  let mergedInputLabelProps;
  if (extraInputLabelProps && Object.keys(extraInputLabelProps).length !== 0 && extraInputLabelProps.classes) {
    mergedInputLabelProps = { ...extraInputLabelProps };
    Object.keys(defaultInputLabelProps.classes).forEach((key) => {
      mergedInputLabelProps.classes[key] = mergedInputLabelProps.classes[key] + ' ' + defaultInputLabelProps.classes[key];
    });
  } else {
    mergedInputLabelProps = defaultInputLabelProps;
  }
  const dimension = {};
  if (minLength) dimension['minLength'] = minLength;
  if (maxLength) dimension['maxLength'] = maxLength;
  if (min) dimension['min'] = min;
  if (max) dimension['max'] = max;

  const mergedInputProps = defaultInput
    ? extraInputProps
    : {
        ...defaultInputProps,
        ...extraInputProps,
        classes: {
          ...defaultInputProps.classes,
          ...Object.keys(extraInputProps.classes || {}).reduce((acc, key) => {
            acc[key] = (defaultInputProps.classes?.[key] || '') + ' ' + (extraInputProps.classes?.[key] || '');
            return acc;
          }, {}),
        },
      };

  const adornment = valueIcon ? (
    valueIconTooltipLabel ? (
      <InputAdornment position='start'>
        <IconWithTooltip
          className='crm-input-icon'
          data={valueIcon}
          title={<Trans i18nKey={valueIconTooltipLabel} components={TRANSLATIONS_TAGS_MAPPING} values={valueIconTooltipValues} />}
        />
      </InputAdornment>
    ) : (
      <InputAdornment position='start'>
        <CrmSvgLoader className='crm-input-icon' data={valueIcon} {...rest} />
      </InputAdornment>
    )
  ) : undefined;

  return (
    <>
      {extraInfo ? (
        <TextField
          variant={variant}
          label={
            <div>
              {label}
              <span className='text_extra_info'>{t('apartments.extrainfo')}</span>
            </div>
          }
          // @ts-ignore
          error={error}
          required={required}
          disabled={disabled}
          value={value === 0 || value ? value : value !== '' && defaultValue ? defaultValue : ''}
          InputLabelProps={defaultInput ? extraInputLabelProps : mergedInputLabelProps}
          InputProps={{
            ...(defaultInput ? extraInputProps : mergedInputProps),
            startAdornment: adornment,
          }}
          className={clsx('crm-input', extraClass, 'extrainfospace')}
          inputProps={dimension}
          {...rest}
        />
      ) : (
        <Tooltip
          title={value}
          disableHoverListener={!tooltip || !disabled}
          disableFocusListener
          disableTouchListener
          className='textfield-input'
          placement='top-start'
        >
          <TextField
            variant={variant}
            label={label}
            error={error}
            required={required}
            disabled={disabled}
            value={value === 0 || value ? value : value !== '' && defaultValue ? defaultValue : ''}
            InputLabelProps={defaultInput ? extraInputLabelProps : mergedInputLabelProps}
            InputProps={{
              ...(defaultInput ? extraInputProps : mergedInputProps),
              startAdornment: adornment,
            }}
            className={clsx('crm-input', extraClass)}
            inputProps={dimension}
            {...rest}
          />
        </Tooltip>
      )}
    </>
  );
};

export default CrmInput;
