import i18n from '@tecma/i18n';
import moment from 'moment';

export const utilityFormatDate = (date: string): string => {
  const formattedDate = new Date(date);
  const options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  };
  return formattedDate.toLocaleDateString(i18n.language, options);
};

export const getDates = (date: string) => {
  const now = moment();
  const tempDate = moment(date);
  const diffTime = now.diff(tempDate);
  const duration = moment.duration(diffTime);
  const days = duration.days();
  const hours = duration.hours();
  let minutes = duration.minutes();
  if (minutes < 10) {
    //@ts-ignore
    minutes = '0' + minutes;
  }
  return { days, hours, minutes };
};
