/**
 * validates an email i.e. checks if the given string is compliant to an email format (x@x.x)
 * @param {string} email email to validate
 */
const r0 =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ValidateEmail = (email) => {
  return email && r0.test(String(email).toLowerCase());
};

export default ValidateEmail;
