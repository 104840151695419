import isProd from './isProd';

/**
 * gets actual host
 * @todo remove this utility
 */
const DetectUrl = () => {
  let hostname = window.location.hostname.indexOf('www.') >= 0 ? window.location.hostname : 'www.' + window.location.hostname;

  const urlParams = new URLSearchParams(window.location.search);
  const hostnameParam = urlParams.get('hostname');

  if (!isProd()) {
    if (hostnameParam && hostnameParam.length > 0 && hostnameParam !== '') {
      delete localStorage['store_followup'];
      delete localStorage['i18nextLng'];
      delete localStorage['followup_dev_hostname'];
      localStorage.setItem('followup_dev_hostname', hostnameParam);
    }

    const followup_dev_hostname = localStorage.getItem('followup_dev_hostname');

    if (followup_dev_hostname && followup_dev_hostname.length > 0 && followup_dev_hostname !== '') {
      return followup_dev_hostname;
    }

    hostname = hostname || process.env.REACT_APP_HOST;
  }

  return hostname;
};

export default DetectUrl;
