import React, { ReactNode } from 'react';

import { Provider } from 'react-redux';

import { reduxStore } from './store';

interface Props {
  children: ReactNode;
}

export const ReduxProvider = ({ children }: Props) => {
  return <Provider store={reduxStore}>{children}</Provider>;
};
