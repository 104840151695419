import React from 'react';

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { observer } from 'mobx-react';

import { useStore } from 'store/storeUtils';

import CrmSnackbar from 'components/common/CrmSnackbar';

const CrmSnackbarAmber = observer(() => {
  const store = useStore();
  return (
    <CrmSnackbar open={store.snackbarAmberOpen} onClose={() => store.setSnackbarAmber(false)}>
      <div className='snackbar-amber-body'>
        <InfoOutlinedIcon style={{ marginRight: '5px' }} />
        {store.snackbarMessage}
      </div>
    </CrmSnackbar>
  );
});

export default CrmSnackbarAmber;
