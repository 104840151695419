import React from 'react';

import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import 'styles/button.scss';

const CrmButton = (props) => {
  const { children, extraClass, onClick, color, ...rest } = props;

  return (
    <Button onClick={onClick} className={`${extraClass}`} variant='contained' color={color} {...rest}>
      {children}
    </Button>
  );
};

/** prop types */
CrmButton.propTypes = {
  /** children: required content (usually text) */
  children: PropTypes.node.isRequired,
  /** onclick: required, action to perform */
  onClick: PropTypes.func.isRequired,
  /** extraClass: additional className */
  extraClass: PropTypes.string,
  /** color: default, primary or secondary */
  color: PropTypes.oneOf(['default', 'primary', 'secondary']),
};

CrmButton.defaultProps = {
  onClick: () => {},
  extraClass: '',
  color: 'primary',
};

export default CrmButton;
