import { gql } from '@apollo/client';

import { pollInterval } from 'client/ClientConstants';

import { SALVATO } from 'constants/hcStatuses';

const STANDARD_QUOTE_DATA_TO_RETRIEVE = `
standardQuote {
  features {
    id
  }
  boxes {
    box
  }
  basements {
    basement
  }
  parkingSpace_bykes {
    parkingSpace_byke
  }
  parkingSpace_cars {
    parkingSpace_car
  }
  promos {
    promo
    percentage
    name
    info
    price
    endDate
    notes
    class
    threshold
  }
  payments {
    name
    endDate
    price
    price_iva
    iva
    percentage
    daysForPayment
    notes
    type
  }
  expenses {
    name
    price
    price_iva
    iva
    percentage
    emptyLabel
    type
  }
  fixedRate {
    percentage
  }
  variableRate {
    percentage
  }
  expiredDate
  daysofexpired
  vatValue
}
`;

const APPARTMENT_DATA_TO_RETRIEVE = `
      id
      code
      name
      price
      status
      floor
      available
      enabled_web
      enabled_desk
      forRent
      selectedBy {
        client {
          id
          firstName
          lastName
          email
          avatar
        }
        status
        expiryOn
        quote
      }
      interestedBy {
        client {
          id
          firstName
          lastName
          email
        }
        status
      }
      updatedOn
      plan {
        id
        code
        name
        SuperficieAppartamento
        SuperficieTotale
        SuperficieGiardino
        SuperficieTerrazzo
        quadrants
        bathroom
        bedroom
        typology {
          id
          name
          rooms
        }
        model {
          id
          name
        }
        extraInfo
      }
      building {
        id
        name
        address
      }
      sides {
        id
        name
        pass
        staircase
        building {
          id
          address
          name
          floors
        }
      }
      availableQuadrants
      superfici
      extraInfo
      ${STANDARD_QUOTE_DATA_TO_RETRIEVE}
`;

const CUSTOM_QUOTE_DATA_TO_RETRIEVE = `
boxes{
  box
  price
  meters
  floor
}
basements{
  basement
  price
  meters
  floor
}
parkingSpace_cars{
  parkingSpace_car
  price
  meters
  floor
}
parkingSpace_bykes{
  parkingSpace_byke
  price
  meters
  floor
}
features{
  id
  name
  type
  price
  floor
  notes
  code
  meters
  width
  length
  visible
  available
  status
  selectedBy{
    client{
      id
    }
    status
    expiryOn
  }
}
`;

const CUSTOM_QUOTE_DATA_TO_RETRIEVE_FEAT = `
boxes{
  box
  price
  meters
  floor
}
basements{
  basement
  price
  meters
  floor
}
parkingSpace_cars{
  parkingSpace_car
  price
  meters
  floor
}
parkingSpace_bykes{
  parkingSpace_byke
  price
  meters
  floor
}
features{
  id
  name
  type
  price
  floor
  notes
  code
  meters
  width
  length
  visible
  available
  status
  selectedBy{
    client{
      id
      firstName
      lastName
    }
    status
    expiryOn
  }
  interestedBy{
    client{
      id
      firstName
      lastName
    }
    status
    expiryOn
  }
}
`;

const PROMO__PRICE_DATA_TO_RETRIVE = `
    status
    apartmentId,
    promoPrice,
`;

const QUOTE_DATA_TO_RETRIVE = `
    id
    status
    quoteNumber
    reserved
    pdf
    client{
      id
      firstName
      lastName
    }
    customQuote {
      ${CUSTOM_QUOTE_DATA_TO_RETRIEVE}
    }
    proposals
    appartment{
      ${APPARTMENT_DATA_TO_RETRIEVE}
    }
    savedDocuments {
      name
      path
      lastModified
    }
    idDocuments {
      name
      type
      base64
    }
    updatedOn
    lastUpdate{
      user{
      firstName
      lastName
      role
      }
      dateUpdate
    }
    lastTransaction {
      status
    }
    vendor
    createdOn
    expiryOn
    favorite
  `;

const PROPOSAL_DATA_TO_RETRIVE = `
    id
    quoteNumber
    client{
      id
      firstName
      lastName
    }
    customQuote {
      ${CUSTOM_QUOTE_DATA_TO_RETRIEVE}
    }
    proposals
    appartment{
      id
      name
      status
      price
      floor
      available
      enabled_web
      enabled_desk
      forRent
      plan {
        id
        name
        SuperficieAppartamento
        SuperficieTotale
        SuperficieGiardino
        SuperficieTerrazzo
        quadrants
        typology {
          id
          name
        }
        model {
          id
          name
        }
        extraInfo
      }
      selectedBy {
        client {
          id
          firstName
          lastName
          email
          avatar
        }
        status
        expiryOn
        quote
      }
      interestedBy {
        client {
          id
          firstName
          lastName
          email
        }
        status
      }
      superfici
      sides {
        id
      }
      extraInfo
    }
    savedDocuments {
      name
      path
      lastModified
    }
    lastTransaction {
      status
    }
    vendor
  `;

const FLOOR_DATA_TO_RETRIEVE = `
    id
    floor
    quadrantsObjects {
      code
    }
    building{
        id
    }
`;

const QUOTE_DATA_TO_RETRIVE_FEAT = `
  id
  status
  quoteNumber
  reserved
  pdf
  client{
    id
    firstName
    lastName
  }
  customQuote {
    ${CUSTOM_QUOTE_DATA_TO_RETRIEVE_FEAT}
  }
  proposals
  appartment{
    ${APPARTMENT_DATA_TO_RETRIEVE}
  }
  savedDocuments {
    name
    path
    lastModified
  }
  idDocuments {
    name
    type
    base64
  }
  updatedOn
  lastUpdate{
    user{
    firstName
    lastName
    role
    }
    dateUpdate
  }
  lastTransaction {
    status
  }
  vendor
  createdOn
  expiryOn
  favorite
  customizations {
    configurationNumber
    vendor
    price
    createdOn
    pdfFileName
    status
  }

`;

const QUOTE_MANAGER_DATA_TO_RETRIVE = `
    id
    status
    quoteNumber
    reserved
    pdf
    client{
      id
      firstName
      lastName
    }
    appartment{
      name
      id
      price
      forRent
      selectedBy {
        client {
          id
        }
        expiryOn
        quote
      }
    }
    lastUpdate{
      user{
      firstName
      lastName
    }
  }
    lastTransaction {
      status
    }
    vendor
    createdOn
    expiryOn
    customizations {
      configurationNumber
      vendor
      price
      createdOn
      pdfFileName
      status
    }
  `;

const CLIENT_DATA_CALENDAR_TO_RETRIEVE = `
  id
  firstName
  lastName
  email
  tel
  `;

const CLIENT_DATA_TO_RETRIEVE = `
      id
      firstName
      lastName
      status
      avatar
      nAppuntamenti
      nPreventiviRichiesti
      notificationLanguage
      appuntamenti{
        id
        startDate
        typology
        vendor {
          id
        }
      }
      email
      tel
      city
      birthdate
      trattamento
      marketing
      profilazione
      note
      interested_appartments {
        appartment
        status
      }
      selected_appartments {
        appartment
        status
      }
      createdOn
      updatedOn
      date_lead
      info{
        budget
        source
        motivazione
        tempo_ricerca
        stato_civile
        caratteristiche
        mutuo
        leva
        arredo
        alternative
        prima_casa
        family{
          adulti
          bambini
          animali
        }
      }
      myhome_version
      coniuge{
        nome
        cognome
        mail
        indirizzo
        tel
      }
      user
      additionalInfo
      reserved
      confirmed
      privacyInformation
      myhomeStatus
      lastAccess
      dateOperationFailed
      actions{
        _id
        actionName
        actionDate
        category
        vendor
        quote
        note
        deleted
        createdOn
      }
      leadScore
      nAccesses
      nReserved
      nProposals
      appointee {
        id
        firstName
        lastName
        email
        role
        isDisabled
      }
`;

const CLIENT_DATA_TO_RETRIEVE_LIGHT = `
      id
      firstName
      lastName
      status
      avatar
      nAppuntamenti
      nPreventiviRichiesti
      email
      tel
      city
      birthdate
      trattamento
      marketing
      profilazione
      note
      interested_appartments {
        appartment
        status
      }
      selected_appartments {
        appartment
        status
      }
      createdOn
      updatedOn
      coniuge{
        nome
        cognome
        mail
        indirizzo
        tel
      }
      info{
        source
        motivazione
        tempo_ricerca
        mutuo
        leva
        arredo
        date_lead
        source
        budget
        motivazione
        tempo_ricerca
        stato_civile
        caratteristiche
        mutuo
        leva
        arredo
        alternative
        prima_casa
        family{
          adulti
          bambini
          animali
        }
      }
      myhome_version
      user
      appuntamenti{
        id
        startDate
        typology
        vendor {
          id
        }
      }
      additionalInfo
      reserved
      confirmed
      privacyInformation
      myhomeStatus
      lastAccess
      dateOperationFailed
      actions{
        _id
        actionName
        actionDate
        category
        vendor
        quote
        note
        deleted
        createdOn
      }
      leadScore
      nAccesses
      nReserved
      nProposals
      motivazione
      appointee {
        id
        firstName
        lastName
        email
        role
        isDisabled
      }
`;

const REMOVED_CLIENT_MOVEMENT_DATA_TO_RETREIVE = `
client {
  ${CLIENT_DATA_TO_RETRIEVE}
}
removedMovement {
  quote
}
`;

const TYPOLOGY_DATA_TO_RETRIEVE = `
      id
      rooms
      name
      active
`;

const VENDOR_DATA_TO_RETRIEVE = `
      id
      firstName
      lastName
      email
      title
      avatar
      project_names
      language
`;

const VENDOR_DATA_TO_RETRIEVE_ALL = `
      id
      firstName
      lastName
      email
      project_ids
      language
      vendorColor
      isDisabled
`;

const USER_DATA_TO_RETRIEVE = `
  id
  firstName
  lastName
  email
  project_ids
  language
  role
  hasGoogleToken
  hasMicrosoftToken
  vendorColor
  TwoFA
  locale
  notificationLanguage
  isDisabled
  `;

const USER_BY_JWT_DATA = `
  id
  firstName
  lastName
  email
  project_ids
  language
  role
  hasGoogleToken
  hasMicrosoftToken
  vendorColor
  TwoFA
  locale
  notificationLanguage
  isDisabled
  consents{
    newsletter{
      privacyPolicy
      subscription
    }
  }
  `;

const USER_DATA_TO_RETRIEVE_ACTIVE_MY_HOME = `
      firstName
      lastName
      email
      id
      project_ids
      notificationLanguage
`;

const IMPORT_DATA_TO_RETRIEVE = `
      id
      title
      binary
      createdOn
`;

const EXPORT_DATA_TO_RETRIEVE = `
      title
      binary
      createdOn
`;

const PROJECT_DATA_TO_RETRIEVE = `
      id
      name
      logo
      vendorLogo
      assetLogin
      hostKey
      assetKey
      feVendorKey
      pdf
      assetsUrl
      displayName
      pageTitles {
        followup
        tuning
      }
      followupConfig
      enabledTools
      currency
      locale
      myhomeConfig
      floorPlanningConfig
      formAppointmentConfig
      policyFlags
      myhomeProDisabled
      ciroIntegrationEnabled
      baseurl
      defaultLang
      homeConfiguratorConfig
      automaticQuoteEnabled
      daysBeforeExpiryAutomaticReservation
      storeAddress
      languages
      projectFlags {
        secondaryMarket {
          enabled
        }
      }
`;

const PROJECT_DATA_CONFIG = `
      formAppointmentConfig
      myhomeConfig
      floorPlanningConfig
`;

const CLIENT_ADDITIONAL_INFO_DATA_TO_RETRIVE = `
      id
      type
      label
      options
      subOptions{
        section
        subSections
        color
      }
      hideInClientInfo
      disabled
      dimension
      required
      defaultValue
      multiple
      position
      path
      name
      tab
      subSection
      project_id
      hiddenProjects
      helperText
      period
      min
      max
      minLength
      maxLength
      step
`;

const BACK_OFFICE_USERS_DATA_TO_RETRIVE = `
      firstName,
      lastName,
      email
      id
      role
      isDisabled
`;

const CALENDAR_DATA_TO_RETRIEVE = `
      id
      startDate
      endDate
      client {
        id
        tel
        email
        lastName
        firstName
      }
      vendor {
        id
        lastName
        firstName
        vendorColor
        isDisabled
      }
      info
      address
      typology
      title
      full
      proposta
      opzione
      interesse
      detAcquisto
      note
      comment
      motivo
      esito
      google_id
      microsoft_id
      infoText
      createdBy
      recurringEvent
      allCompany
`;

const DELETE_DATA_TO_RETRIVE = `
      success
      vendor
`;

const FEATURE_DATA_TO_RETRIEVE = `
      id
      name
      type
      price
      floor
      meters
      visible
      available
      status
      selectedBy{
        client {
          id
          firstName
          lastName
        }
        status
        expiryOn
      }
      interestedBy{
        client {
          id
          firstName
          lastName
        }
        status
        expiryOn
      }
`;

const ACTION_DATA_TO_RETRIEVE = `
      actionName
      actionDate
      category
      vendor
      quote
      _id
      note
      createdOn
`;

const GET_ALL_APPARTMENTS = gql`
  query getAllAppartments ($project_id: ID!, $lean: Boolean = false, $page: Int, $perPage: Int) {
    getAllAppartments (project_id: $project_id, lean: $lean, page: $page, perPage: $perPage) {
      ${APPARTMENT_DATA_TO_RETRIEVE}
    }
  }`;

const GET_PROJECT_INFO = gql`
  query getProjectInfoByHost ($host: String!, $url: String) {
    getProjectInfoByHost (host: $host, url: $url) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`;

const GET_PROJECT_INFO_DEFAULT_OPTIONS = (hostname) => {
  return {
    variables: {
      host: hostname,
      url: window.location.href,
    },
  };
};

const GET_PROJECT_FEATURES = gql`
query getFeatures ($project_id: ID!, $lean: Boolean = false) {
  getFeatures (project_id: $project_id, lean: $lean) {
    ${FEATURE_DATA_TO_RETRIEVE}
  }
}`;
const GET_PROJECT_FEATURES_DEFAULT_OPTIONS = (projectId, lean = true) => {
  return {
    variables: {
      project_id: projectId,
      lean,
    },
    fetchPolicy: 'cache-and-network',
  };
};
const GET_PROJECT_FEATURES_NETWORK_OPTIONS = (pId, lean = true) => {
  return {
    variables: {
      project_id: pId,
      lean,
    },
    fetchPolicy: 'network-only',
  };
};

const PROJECTID_GENERIC_OPTION_NOCACHE = (projectId, clientIds, lean) => {
  return {
    variables: {
      project_id: projectId,
      clients_id: clientIds,
      lean,
    },
    fetchPolicy: 'no-cache',
  };
};

const GET_ALL_APPARTMENTS_DEFAULT_OPTIONS = (pId, lean = true, page, perPage) => {
  return {
    variables: {
      project_id: pId,
      lean,
      page,
      perPage,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_ALL_APPARTMENTS_NETWORK_OPTIONS = (pId, lean = true, page, perPage) => {
  return {
    variables: {
      project_id: pId,
      lean,
      page,
      perPage,
    },
    fetchPolicy: 'network-only',
  };
};

const GET_ALL_CLIENTS = gql`
  query getAllClients($project_id: ID!, $page: Int, $perPage: Int) {
    getAllClientsFollowUp(project_id: $project_id, page: $page, perPage: $perPage) {
      ${CLIENT_DATA_TO_RETRIEVE_LIGHT}
    }
  }`;

const GET_ALL_CLIENTS_UPDATE = gql`
  query getAllUpdatedClientsFollowUp($project_id: ID!, $updatedAfter: IsoDate!) {
    getAllUpdatedClientsFollowUp(project_id: $project_id, updatedAfter: $updatedAfter) {
      elements {${CLIENT_DATA_TO_RETRIEVE_LIGHT}}
      dates {
        from
        to
      }
    }
  }
`;

const GET_ALL_CLIENTS_DEFAULT_OPTIONS = (pId, page, perPage) => {
  return {
    variables: {
      project_id: pId,
      page,
      perPage,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_ALL_CLIENTS_WITH_LEAD_SCORE = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_ALL_CLIENTS_ADDITIONAL_INFO = gql`
  query getAllAdditionalInfoByProject($project_id: ID!) {
    getAllAdditionalInfoByProject(project_id: $project_id) {
      ${CLIENT_ADDITIONAL_INFO_DATA_TO_RETRIVE}
    }
  }`;

const GET_ALL_CLIENTS_ADDITIONAL_INFO_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    fetchPolicy: 'cache-and-network',
  };
};

const GET_BACK_OFFICE_USERS = gql`
  query getBackOfficeUsers($project_id: ID!, $backOfficeUsersInput: BackOfficeUsersFilterInput) {
    getBackOfficeUsers(project_id: $project_id, backOfficeUsersInput: $backOfficeUsersInput) {
      ${BACK_OFFICE_USERS_DATA_TO_RETRIVE}
    }
  }`;

const GET_BACK_OFFICE_USERS_DEFAULT_OPTIONS = (pId, input = {}) => {
  // @ts-ignore
  const { isDisabled, roles } = input;
  return {
    variables: {
      project_id: pId,
      backOfficeUsersInput: {
        isDisabled,
        roles,
      },
    },
    fetchPolicy: 'cache-and-network',
  };
};

const GET_CLIENT_ADDITIONAL_INFO_BY_NAME = gql`
query getAdditionalInfoByName($project_id: ID!, $name: String!) {
  getAdditionalInfoByName(project_id: $project_id, name: $name) {
    ${CLIENT_ADDITIONAL_INFO_DATA_TO_RETRIVE}
  }
}`;

const GET_CLIENT_ADDITIONAL_INFO_BY_NAME_DEFAULT_OPTIONS = (pId, name) => {
  return {
    variables: {
      project_id: pId,
      name: name,
    },
    fetchPolicy: 'cache-and-network',
  };
};

const UPDATE_ADDITIONAL_INFO = gql`
  mutation updateAdditionalInfo($project_id: ID!, $id: ID!, $input: ClientAdditionalInfoInput!){
    updateAdditionalInfo(project_id: $project_id, id: $id, input: $input){
      ${CLIENT_ADDITIONAL_INFO_DATA_TO_RETRIVE}
    }
  }
`;

const UPDATE_ADDITIONAL_INFO_DEFAULT_OPTIONS = (pId, id, input) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      input: input,
    },
  };
};

const UPDATE_PERTINENZA_INFO = gql`
  mutation updateFeatures($project_id: ID!, $id: ID!, $input: FeatureInput) {
    updateFeatures(project_id: $project_id, id: $id, input: $input){
      ${FEATURE_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_PERTINENZA_INFO_DEFAULT_OPTIONS = (pId, id, input) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      input: input,
    },
  };
};

const GET_QUOTES_V2 = gql`
  query getQuotesWithClientsV2($project_id: ID!) {
    getQuotesWithClientsV2(project_id: $project_id) {
      ${PROPOSAL_DATA_TO_RETRIVE}
    }
  }`;

const GET_QUOTES_V2_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_ALL_FLOORS = gql`
  query getAllFloors($project_id: ID!, $lean: Boolean = false) {
    getAllFloors(project_id: $project_id, lean: $lean) {
      ${FLOOR_DATA_TO_RETRIEVE}
    }
  }
`;

const GET_QUOTES_MANAGER = gql`
  query getQuotesWithClientsFollowup($project_id: ID!, $page: Int, $perPage: Int) {
    getQuotesWithClientsFollowup(project_id: $project_id, page: $page, perPage: $perPage) {
      ${QUOTE_MANAGER_DATA_TO_RETRIVE}
    }
  }`;

const GET_QUOTES_MANAGER_DEFAULT_OPTIONS = (pId, page, perPage) => {
  return {
    variables: {
      project_id: pId,
      page,
      perPage,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_APARTMENTS_PROMO_PRICE = gql`
  query getApartmentsPromoPrice($project_id: ID!, $apartments:[PromoPriceApartment]!) {
    getApartmentsPromoPrice(project_id: $project_id, apartments: $apartments) {
      ${PROMO__PRICE_DATA_TO_RETRIVE}
    }
  }`;

const GET_APARTMENTS_PROMO_PRICE_DEFAULT_OPTIONS = (project_id, apartments) => {
  return {
    variables: {
      project_id,
      apartments,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_QUOTE_DOCUMENT = gql`
  mutation getProposalDocsByQuote($project_id: ID!, $id: ID!) {
    getProposalDocsByQuote(project_id: $project_id, id: $id) {
      proposal
    }
  }
`;

const GET_QUOTE_DOCUMENT_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
  };
};

const GET_QUOTE_DOCUMENT_V2 = gql`
  mutation getProposalDocsByQuoteV2($project_id: ID!, $id: ID!) {
    getProposalDocsByQuoteV2(project_id: $project_id, id: $id) {
      proposal
    }
  }
`;

const GET_QUOTE_DOCUMENT_V2_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
  };
};

const UPLOAD_DOCUMENTS = gql`
  mutation uploadMultipleClientDocuments($documents: [ClientDocumentInput]!, $client_id: ID!, $project_id: ID!, $quote_ids: [ID]) {
    uploadMultipleClientDocuments(documents: $documents, client_id: $client_id, project_id: $project_id, quote_ids: $quote_ids)
  }
`;

const UPLOAD_DOCUMENTS_DEFAULT_OPTIONS = (documents, client_id, project_id, quote_ids = []) => {
  return {
    variables: {
      documents,
      client_id,
      project_id,
      quote_ids,
    },
  };
};

const GET_CLIENT_DOCUMENT = gql`
  query getClientDocumentsByClientV2($project_id: ID!, $client_id: ID!) {
    getClientDocumentsByClientV2(project_id: $project_id, client_id: $client_id)
  }
`;

const GET_CLIENT_DOCUMENT_DEFAULT_OPTIONS = (project_id, client_id) => {
  return {
    variables: {
      project_id,
      client_id,
    },
    fetchPolicy: 'network-only',
  };
};

const DELETE_DOCUMENT = gql`
  mutation deleteDocument($project_id: ID!, $id: ID!) {
    deleteDocument(project_id: $project_id, id: $id)
  }
`;

const DELETE_DOCUMENT_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
  };
};

const UPDATE_DOCUMENT = gql`
  mutation updateDocument($project_id: ID!, $id: ID!, $document: UpdateDocumentInput) {
    updateDocument(project_id: $project_id, id: $id, document: $document)
  }
`;

const UPDATE_DOCUMENT_DEFAULT_OPTIONS = (pId, id, document) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      document: document,
    },
  };
};

const CREATE_ADDITIONAL_INFO = gql`
  mutation createCustomAdditionalInfo($project_id: ID!, $input: ClientAdditionalInfoInput!){
    createCustomAdditionalInfo(project_id: $project_id, input: $input){
      ${CLIENT_ADDITIONAL_INFO_DATA_TO_RETRIVE}
    }
  }
`;

const CREATE_ADDITIONAL_INFO_DEFAULT_OPTIONS = (pId, input) => {
  return {
    variables: {
      project_id: pId,
      input: input,
    },
  };
};

const DELETE_ADDITIONAL_INFO = gql`
  mutation deleteCustomAdditionalInfo($project_id: ID!, $id: ID!) {
    deleteCustomAdditionalInfo(project_id: $project_id, id: $id)
  }
`;

const DELETE_ADDITIONAL_INFO_DEFAULT_OPTIONS = (pId, inputId) => {
  return {
    variables: {
      project_id: pId,
      id: inputId,
    },
  };
};

const GET_QUOTES_BYAPARTMENT = gql`
  query getQuotesByApartment($project_id: ID!, $apartment: ID!) {
    getQuotesByApartment(project_id: $project_id, appartment: $apartment) {
      ${QUOTE_DATA_TO_RETRIVE}
    }
  }`;

const GET_QUOTES_BYAPARTMENT_DEFAULT_OPTIONS = (pId, apartment) => {
  return {
    variables: {
      project_id: pId,
      apartment: apartment,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_QUOTES_BYCLIENT = gql`
  query getQuotesByClientFollowup($project_id: ID!, $client: ID!) {
    getQuotesByClientFollowup(project_id: $project_id, client: $client) {
      ${QUOTE_DATA_TO_RETRIVE_FEAT}
    }
  }`;

const GET_QUOTES_BYCLIENT_DEFAULT_OPTIONS = (pId, client) => {
  return {
    variables: {
      project_id: pId,
      client: client,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_QUOTES_BYCLIENT_NETWORK_OPTIONS = (pId, client) => {
  return {
    variables: {
      project_id: pId,
      client: client,
    },
    fetchPolicy: 'network-only',
  };
};

const UPDATE_EXPIRED_QUOTE = gql`
  mutation updateExpiredQuotes($project_id: ID!, $id: ID!, $input: ExpiredQuoteInput!){
    updateExpiredQuotes(project_id: $project_id, id: $id, input: $input){
      ${QUOTE_DATA_TO_RETRIVE}
    }
  }
`;

const UPDATE_RESERVED_QUOTE = gql`
  mutation updateReservedTimeQuotes($project_id: ID!, $id: ID!, $input: ExpiredQuoteInput!){
    updateReservedTimeQuotes(project_id: $project_id, id: $id, input: $input){
      ${QUOTE_DATA_TO_RETRIVE}
    }
  }
`;

const UPDATE_EXPIRED_QUOTE_DEFAULT_OPTIONS = (pId, id, input) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      input: input,
    },
  };
};

const UPDATE_RESERVED_QUOTE_DEFAULT_OPTIONS = (pId, id, input) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      input: input,
    },
  };
};

const UPDATE_QUOTE_PROPOSAL = gql`
  mutation updateQuotesProposalStatus($project_id: ID!, $quoteId: ID!, $proposalId: ID!, $input: QuoteProposalStatusInput!){
    updateQuotesProposalStatus(project_id: $project_id, quoteId: $quoteId, proposalId: $proposalId, input: $input){
      ${QUOTE_DATA_TO_RETRIVE}
    }
  }
`;

const UPDATE_QUOTE_PROPOSAL_DEFAULT_OPTIONS = (pId, quoteId, proposalId, input) => {
  return {
    variables: {
      project_id: pId,
      quoteId: quoteId,
      proposalId: proposalId,
      input: input,
    },
  };
};

const DELETE_QUOTE = gql`
  mutation deleteQuotes($project_id: ID!, $id: ID!){
    deleteQuotes(project_id: $project_id, id: $id){
      ${QUOTE_DATA_TO_RETRIVE}
    }
  }
`;

const DELETE_QUOTE_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
  };
};

const GET_CLIENT = gql`
  query getClient($project_id: ID!, $id: ID!, $getQuotes: Boolean = false, $getLastAccess: Boolean = false) {
    getClient(project_id: $project_id, id: $id, getQuotes: $getQuotes, getLastAccess: $getLastAccess) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }`;

const GET_CLIENT_DEFAULT_OPTIONS = (pId, id, getQuotes, getLastAccess) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      getQuotes,
      getLastAccess,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_CLIENTS_DATA = gql`
  query getDataClients($project_id: ID!, $lean: Boolean = false) {
    getDataClients(project_id: $project_id, lean: $lean) {
      ${CLIENT_DATA_CALENDAR_TO_RETRIEVE}
    }
  }`;

const GET_CLIENTS_DATA_DEFAULT_OPTIONS = (pId, lean = true) => {
  return {
    variables: {
      project_id: pId,
      lean,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_TYPOLOGIES = gql`
  query getAllTypologies($project_id: ID!) {
    getAllTypologies(project_id: $project_id) {
      ${TYPOLOGY_DATA_TO_RETRIEVE}
    }
  }
`;

const GET_TYPOLOGIES_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_ALL_CALENDARS_FO = gql`
query getCalendars($project_id: ID!, $filter: CalendarFilter) {
  getCalendars(project_id: $project_id, filter: $filter){
    ${CALENDAR_DATA_TO_RETRIEVE}
  }
}`;

const GET_ALL_CALENDARS = gql`
query getCalendarsByVendor($project_id: ID!, $vendor: ID!) {
  getCalendarsByVendor(project_id: $project_id, vendor: $vendor){
    ${CALENDAR_DATA_TO_RETRIEVE}
  }
}`;

const GET_ALL_CALENDARS_BY_VENDORS = gql`
query getCalendarsByVendorList($project_id: ID!, $vendor: [ID]!) {
  getCalendarsByVendorList(project_id: $project_id, vendor: $vendor){
    ${CALENDAR_DATA_TO_RETRIEVE}
  }
}`;

const GET_CALENDAR = gql`
query getCalendar($project_id: ID!, $id: ID!) {
  getCalendar(project_id: $project_id, id: $id){
    ${CALENDAR_DATA_TO_RETRIEVE}
  }
}`;

const IS_FIRST_CALENDAR = gql`
  query isFirstCalendar($project_id: ID!, $id: ID!) {
    isFirstCalendar(project_id: $project_id, id: $id)
  }
`;

const GET_ALL_CALENDARS_FO_DEFAULT_OPTIONS = (pId, filter) => {
  return {
    variables: {
      project_id: pId,
      filter: filter,
    },
    pollInterval,
    fetchPolicy: 'no-cache',
  };
};

const GET_ALL_CALENDARS_BY_VENDORS_DEFAULT_OPTIONS = (pId, vId) => {
  return {
    variables: {
      project_id: pId,
      vendor: vId.map((v) => v.value),
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_ALL_CALENDARS_DEFAULT_OPTIONS = (pId, vId) => {
  return {
    variables: {
      project_id: pId,
      vendor: vId,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_CALENDAR_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const IS_FIRST_CALENDAR_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const INSERT_CLIENT_INTEREST = gql`
  mutation setClientInterest($project_id: ID!, $clientId: ID!, $appartmentId: ID!, $vendorId: ID!, $featureId: [ID]) {
    setClientInterest(project_id: $project_id, clientId: $clientId, appartmentId: $appartmentId, vendorId: $vendorId, featureId: $featureId) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const INSERT_CLIENT_INTEREST_DEFAULT_OPTIONS = (pId, id, app, vendor, featureId) => {
  return {
    variables: {
      project_id: pId,
      clientId: id,
      appartmentId: app,
      vendorId: vendor,
      featureId: featureId,
    },
  };
};

const INSERT_CLIENT_APPARTMENT = gql`
  mutation setClientAppartment($project_id: ID!, $clientId: ID!, $appartmentId: ID!, $status: String!, $vendorId: ID!,$quadrant: String, $quoteId: ID, $selectedQuoteId: ID, $featureId: [ID]) {
    setClientAppartment(project_id: $project_id, clientId: $clientId, appartmentId: $appartmentId, status: $status, vendorId: $vendorId, quadrant:$quadrant, quoteId: $quoteId, selectedQuoteId: $selectedQuoteId, featureId: $featureId) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const INSERT_CLIENT_APPARTMENT_DEFAULT_OPTIONS = (pId, id, app, status, vendor, quadrant, quote, selectedQuote, feat) => {
  return {
    variables: {
      project_id: pId,
      clientId: id,
      appartmentId: app,
      status: status,
      vendorId: vendor,
      quadrant: quadrant,
      quoteId: quote,
      selectedQuoteId: selectedQuote,
      featureId: feat,
    },
  };
};

const INSERT_CLIENT_FEATURE = gql`
  mutation setClientFeature($project_id: ID!, $clientId: ID!, $featureId: ID!, $status: String!, $vendorId: ID!) {
    setClientFeature(project_id: $project_id, clientId: $clientId, featureId: $featureId, status: $status, vendorId: $vendorId) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const INSERT_CLIENT_FEATURE_DEFAULT_OPTIONS = (pId, id, feature, status, vendor) => {
  return {
    variables: {
      project_id: pId,
      clientId: id,
      featureId: feature,
      status: status,
      vendorId: vendor,
    },
  };
};

const UPDATE_CLIENT_FEATURE = gql`
  mutation updateClientFeature($project_id: ID!, $clientId: ID!, $featureId: ID!, $status: String!, $vendorId: ID!) {
    updateClientFeature(project_id: $project_id, clientId: $clientId, featureId: $featureId, status: $status, vendorId: $vendorId) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_CLIENT_FEATURE_DEFAULT_OPTIONS = (pId, id, feature, status, vendor) => {
  return {
    variables: {
      project_id: pId,
      clientId: id,
      featureId: feature,
      status: status,
      vendorId: vendor,
    },
  };
};

const CREATE_CALENDAR = gql`
  mutation createCalendars($project_id: ID!, $input: CalendarInput!){
    createCalendars(project_id: $project_id, input: $input){
      ${CALENDAR_DATA_TO_RETRIEVE}
    }
  }
`;

const CREATE_CALENDAR_DEFAULT_OPTIONS = (pId, event, vId, actionId) => {
  return {
    variables: {
      project_id: pId,
      input: {
        vendor: event.data.vendor && event.data.vendor.value ? event.data.vendor.value : vId,
        startDate: event.data.start.toISOString(),
        endDate: event.data.end.toISOString(),
        client: event.data.clientId,
        typology: event.data.type,
        address: event.data.place,
        full: event.data.full,
        title: event.data.title,
        proposta: event.data.proposta,
        opzione: event.data.opzione,
        interesse: event.data.interesse,
        detAcquisto: event.data.detAcquisto,
        note: event.data.note,
        comment: event.data.comment,
        motivo: event.data.motivo,
        esito: event.data.esito,
        infoText: event.data.infoText,
        createdBy: vId,
        actionId,
      },
    },
  };
};

const CREATE_CALENDAR_LIST = gql`
mutation createCalendarList($project_id: ID!, $input: [CalendarInput]!){
  createCalendarList(project_id: $project_id, input: $input){
    ${CALENDAR_DATA_TO_RETRIEVE}
  }
}
`;

const CREATE_CALENDAR_LIST_DEFAULT_OPTIONS = (pId, event) => {
  return {
    variables: {
      project_id: pId,
      input: event,
    },
  };
};

const DELETE_CALENDAR_LIST = gql`
  mutation deleteCalendarRecurrent($project_id: ID!, $id: String!) {
    deleteCalendarRecurrent(project_id: $project_id, id: $id){
      ${CALENDAR_DATA_TO_RETRIEVE}
    }
  }
`;

const DELETE_CALENDAR_LIST_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
  };
};

const UPDATE_CALENDAR = gql`
  mutation updateCalendars($project_id: ID!, $id: ID!, $mailUpdate: Boolean, $input: CalendarInput!){
    updateCalendars(project_id: $project_id, id: $id, mailUpdate: $mailUpdate, input: $input){
      ${CALENDAR_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_CALENDAR_DEFAULT_OPTIONS = (pId, event, vId, mailUpdate = false) => {
  return {
    variables: {
      project_id: pId,
      id: event.data.id,
      mailUpdate: mailUpdate ? mailUpdate : false,
      input: {
        vendor: event.data.vendor && event.data.vendor.value ? event.data.vendor.value : vId,
        startDate: event.data.start.toISOString(),
        endDate: event.data.end.toISOString(),
        client: event.data.clientId,
        typology: event.data.type,
        full: event.data.full,
        title: event.data.title,
        address: event.data.place,
        proposta: event.data.proposta,
        opzione: event.data.opzione,
        interesse: event.data.interesse,
        detAcquisto: event.data.detAcquisto,
        note: event.data.note,
        comment: event.data.comment,
        motivo: event.data.motivo,
        esito: event.data.esito,
        infoText: '',
        info: event.data.info,
      },
    },
  };
};

const DELETE_CALENDAR = gql`
  mutation deleteCalendars($project_id: ID!, $id: ID!) {
    deleteCalendars(project_id: $project_id, id: $id)
  }
`;

const DELETE_AND_REASSIGN_CALENDAR = gql`
  mutation deleteAndReassignCalendars($project_id: ID!, $id: ID!) {
    deleteAndReassignCalendars(project_id: $project_id, id: $id){
      ${DELETE_DATA_TO_RETRIVE}
    }
  }
`;

const DELETE_CALENDAR_DEFAULT_OPTIONS = (pId, event) => {
  return {
    variables: {
      project_id: pId,
      id: event.data.id,
    },
  };
};

const GET_PROJECT_INFORMATIONS = gql`
  query getProjectInformations($project_id: ID!) {
    getProjectInformations(project_id: $project_id) {
      name
      tipologia
      numero
    }
  }
`;

const GET_PROJECT_INFORMATIONS_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
  };
};

const GET_PROJECT_INFORMATIONS_APPOINTMENT = gql`
  query getProjectInformationsAppointment($project_id: ID!, $clients_id: [String]) {
    getProjectInformationsAppointment(project_id: $project_id, clients_id: $clients_id) {
      name
      tipologia
      numero
    }
  }
`;

const GET_PROJECT_INFORMATIONS_APPOINTMENT_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
  };
};

const GET_PROJECT_CONFIG = gql`
  query getProject($id: ID!) {
    getProject(id: $id) {
      ${PROJECT_DATA_CONFIG}
    }
  }
`;

const GET_PROJECT_CONFIG_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      id: pId,
    },
  };
};

const UPDATE_PROJECT_CONFIG = gql`
  mutation updateProjects($id: ID!, $input: ProjectInput!){
    updateProjects(id: $id, input: $input){
      ${PROJECT_DATA_CONFIG}
    }
  }
`;

const UPDATE_PROJECT_CONFIG_DEFAULT_OPTIONS = (id, input) => {
  return {
    variables: {
      id: id,
      input: input,
    },
  };
};

const REMOVE_CLIENT_INTEREST = gql`
  mutation removeClientInterest($project_id: ID!, $clientId: ID!, $appartmentId: ID!, $featureId: [ID]) {
    removeClientInterest(project_id: $project_id, clientId: $clientId, appartmentId: $appartmentId, featureId: $featureId) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const REMOVE_CLIENT_INTEREST_DEFAULT_OPTIONS = (pId, id, app, feat) => {
  return {
    variables: {
      project_id: pId,
      clientId: id,
      appartmentId: app,
      featureId: feat,
    },
  };
};

const REMOVE_CLIENT_FEATURE = gql`
  mutation removeClientFeature($project_id: ID!, $clientId: ID!, $featureId: ID!) {
    removeClientFeature(project_id: $project_id, clientId: $clientId, featureId: $featureId) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const REMOVE_CLIENT_FEATURE_DEFAULT_OPTIONS = (pId, id, feat) => {
  return {
    variables: {
      project_id: pId,
      clientId: id,
      featureId: feat,
    },
  };
};

const REMOVE_CLIENT_APPARTMENT = gql`
  mutation removeClientAppartment($project_id: ID!, $clientId:ID!, $vendorId: ID, $appartmentId: ID!, $featureId: [ID]) {
    removeClientAppartment(project_id: $project_id, clientId: $clientId, vendorId: $vendorId, appartmentId: $appartmentId, featureId: $featureId) {
      ${REMOVED_CLIENT_MOVEMENT_DATA_TO_RETREIVE}
    }
  }
`;

const REMOVE_CLIENT_APPARTMENT_DEFAULT_OPTIONS = (pId, id, vendor, app, feat) => {
  return {
    variables: {
      project_id: pId,
      clientId: id,
      vendorId: vendor,
      appartmentId: app,
      featureId: feat,
    },
  };
};

const UPDATE_APPARTEMENT = gql`
  mutation updateAppartments($project_id: ID!, $id: ID!, $input: AppartmentInput!) {
    updateAppartments(project_id: $project_id, id: $id, input: $input) {
      ${APPARTMENT_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_APPARTEMENT_DEFAULT_OPTIONS = (pId, id, input) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      input: input,
    },
  };
};

const UPLOAD_APARTEMENT_PLANIMETRY = gql`
  mutation uploadApartmentPlanimetry($project_id: ID!, $name: String!, $type: String!, $base64: String!) {
    uploadApartmentPlanimetry(project_id: $project_id, name: $name, type: $type, base64: $base64)
  }
`;
const UPLOAD_APARTEMENT_PLANIMETRY_DEFAULT_OPTIONS = (pId, nameImage, typeImage, base64Image) => {
  return {
    variables: {
      project_id: pId,
      name: nameImage,
      type: typeImage,
      base64: base64Image,
    },
  };
};

const IMPORT_APARTMENT_EXCEL = gql`
  mutation importExcel($file: String!, $project_id: ID!, $userId: String!, $justValidation: Boolean) {
    importExcel(file: $file, project_id: $project_id, userId: $userId, justValidation: $justValidation)
  }
`;
const IMPORT_CONFIGURATION_EXCEL = gql`
  mutation importExcelHC($file: String!, $project_id: ID!, $userId: String!, $justValidate: Boolean) {
    importExcelHC(file: $file, project_id: $project_id, userId: $userId, justValidate: $justValidate)
  }
`;

const IMPORT_APARTMENT_EXCEL_DEFAULT_OPTIONS = (file, projectId, id, justValidation) => {
  return {
    variables: {
      file: file,
      project_id: projectId,
      userId: id,
      justValidation,
    },
  };
};
const IMPORT_CONFIGURATION_EXCEL_DEFAULT_OPTIONS = (file, projectId, id, justValidate) => {
  return {
    variables: {
      file: file,
      project_id: projectId,
      userId: id,
      justValidate,
    },
  };
};

const GET_IMPORT_APPARTMENT = gql`
query getLastImportExcel($project_id: ID! ){
  getLastImportExcel(project_id: $project_id){
    ${IMPORT_DATA_TO_RETRIEVE}
  }
}
`;

const GET_IMPORT_APPARTMENT_DEAFULT_OPTIONS = (project_id) => {
  return {
    variables: {
      project_id: project_id,
    },
    fetchPolicy: 'network-only',
  };
};

const IMPORT_CLIENTS_EXCEL = gql`
  mutation importClientsExcel($file: String!, $project_id: ID!) {
    importClientsExcel(file: $file, project_id: $project_id)
  }
`;

const IMPORT_CLIENTS_EXCEL_DEFAULT_OPTIONS = (file, projectId) => {
  return {
    variables: {
      file: file,
      project_id: projectId,
    },
  };
};

const GET_EXPORT_APPARTMENT = gql`
query exportExcel($project_id: ID! ){
  exportExcel(project_id: $project_id){
    ${EXPORT_DATA_TO_RETRIEVE}
  }
}
`;
const GET_EXPORT_CONFIGURATION = gql`
query exportExcelHC($project_id: ID! ){
  exportExcelHC(project_id: $project_id){
    ${EXPORT_DATA_TO_RETRIEVE}
  }
}

`;
const GET_EXPORT_CONFIGURATION_TEMPLATE = gql`
query exportTemplateHC($project_id: ID! ){
  exportTemplateHC(project_id: $project_id){
    ${EXPORT_DATA_TO_RETRIEVE}
  }
}
`;

const GET_EXPORT_APPARTMENT_DEAFULT_OPTIONS = (project_id) => {
  return {
    variables: {
      project_id: project_id,
    },
    fetchPolicy: 'network-only',
  };
};
const GET_EXPORT_CONFIGURATION_DEFAULT_OPTIONS = (project_id) => {
  return {
    variables: {
      project_id: project_id,
    },
    fetchPolicy: 'no-cache',
  };
};
const GET_EXPORT_CONFIGURATION_TEMPLATE_DEFAULT_OPTIONS = (project_id) => {
  return {
    variables: {
      project_id: project_id,
    },
    fetchPolicy: 'no-cache',
  };
};

const UPDATE_CLIENT = gql`
  mutation updateClients($project_id: ID!, $id: ID!, $input: ClientInput!) {
    updateClients(project_id: $project_id, id: $id, input: $input) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_CLIENT_DEFAULT_OPTIONS = (pId, id, input) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      input: input,
    },
  };
};

const UPDATE_CLIENT_TAGS = gql`
mutation updateClientTags($project_id: ID!, $id: ID!, $tag: [String]!) {
  updateClientTags(project_id: $project_id, id: $id,tag: $tag) {
    ${CLIENT_DATA_TO_RETRIEVE}
  }
}
`;
const UPDATE_CLIENT_TAGS_DEFAULT_OPTIONS = (pId, id, tag) => {
  return {
    variables: {
      project_id: pId,
      id: id,
      tag: tag,
    },
  };
};

const DELETE_CLIENT = gql`
  mutation deleteClients($project_id: ID!, $id: ID!) {
    deleteClients(project_id: $project_id, id: $id)
  }
`;

const DELETE_CLIENT_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
  };
};

const DELETE_MY_HOME = gql`
  mutation deleteUserByClientIdProjectId($clientId: ID!, $project_id: ID!) {
    deleteUserByClientIdProjectId(clientId: $clientId, project_id: $project_id)
  }
`;

const DELETE_MY_HOME_DEFAULT_OPTIONS = (id, pId) => {
  return {
    variables: {
      clientId: id,
      project_id: pId,
    },
  };
};

const CREATE_CLIENT = gql`
  mutation createClients($project_id: ID!, $input: ClientInput!) {
    createClients(project_id: $project_id, input: $input) {
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const CREATE_CLIENT_DEFAULT_OPTIONS = (pId, input) => {
  return {
    variables: {
      project_id: pId,
      input: input,
    },
  };
};

const LOGIN = gql`
  mutation loginTecma($input: LoginByProjectInput!) {
    loginByProject(input: $input) {
      token {
        accessToken
        refreshToken
        tokenType
        expiresIn
      }
      user {
        id
        firstName
        lastName
        createdOn
        email
        language
        role
        TwoFA
      }
    }
  }
`;

const LOGIN_DEFAULT_OPTIONS = (email, password, project_id) => {
  return {
    variables: {
      email: email,
      password: password,
      project_id: project_id,
    },
  };
};

const GET_VENDOR = gql`
  query getVendorByEmail($email :String!) {
    getVendorByEmail(email: $email) {
      ${VENDOR_DATA_TO_RETRIEVE}
    }
  }`;

const GET_ALL_USERS = gql`
  query getAllUsers($project_id: ID){
    getAllUsers(project_id: $project_id){
      ${USER_DATA_TO_RETRIEVE}
    }
  }
 `;

const GET_ALL_USERS_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    fetchPolicy: 'cache-and-network',
  };
};

const UPDATE_USER = gql`
  mutation updateUsers( $id: ID!, $input: UserInput!) {
    updateUsers( id: $id, input: $input) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_USER_DEFAULT_OPTIONS = (id, input) => {
  return {
    variables: {
      id: id,
      input: input,
    },
  };
};

const UPDATE_USER_COLOR = gql`
mutation updateUserColor( $id: ID!, $project_id:ID!, $vendorColor: String!) {
  updateUserColor( id: $id, project_id:$project_id, vendorColor: $vendorColor) {
    ${USER_DATA_TO_RETRIEVE}
  }
}
`;

const UPDATE_USER_COLOR_DEFAULT_OPTIONS = (id, project_id, vendorColor) => {
  return {
    variables: {
      id: id,
      project_id: project_id,
      vendorColor: vendorColor,
    },
  };
};

const UPDATE_USER_BY_ACCOUNT_MANAGER = gql`
  mutation updateUsersByAccountManager( $id: ID!, $project_id: ID!, $input: UserInput!) {
    updateUsersByAccountManager( id: $id, project_id: $project_id, input: $input) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const UPDATE_USER_BY_ACCOUNT_MANAGER_DEFAULT_OPTIONS = (id, project_id, input) => {
  return {
    variables: {
      id: id,
      project_id: project_id,
      input: input,
    },
  };
};

const CREATE_ACTION = gql`
mutation addAction($project_id: ID!, $action: ActionInput!) {
  addAction(project_id: $project_id, action: $action) {
    ${ACTION_DATA_TO_RETRIEVE}
  }
}
`;
const CREATE_ACTION_DEFAULT_OPTIONS = (project_id, action) => {
  return {
    variables: {
      project_id,
      action,
    },
  };
};

const DELETE_USER = gql`
  mutation deleteUsersByAccountManager($id: ID!, $project_id: ID!) {
    deleteUsersByAccountManager(id: $id, project_id: $project_id)
  }
`;

const DELETE_USER_DEFAULT_OPTIONS = (pId, id) => {
  return {
    variables: {
      project_id: pId,
      id: id,
    },
  };
};

const CREATE_USER_MANAGER = gql`
  mutation createUsersByAccountManager($project_id: ID!, $input: UserInput!) {
    createUsersByAccountManager(project_id: $project_id, input: $input) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const CREATE_USER_DEFAULT_OPTIONS = (pId, input) => {
  return {
    variables: {
      project_id: pId,
      input: input,
    },
  };
};

const CONFIRM_PASSWORD_USER = gql`
  mutation confirmUserAndChangePassword($project_id: ID!, $token: String!, $password: String!, $newPassword: String!) {
    confirmUserAndChangePasswordBP(project_id: $project_id, token: $token, password: $password, newPassword: $newPassword)
  }
`;

const CONFIRM_PASSWORD_USER_DEFAULT_OPTIONS = (project_id, token, password, newPassword) => {
  return {
    variables: {
      token,
      password,
      newPassword,
      project_id,
    },
  };
};

const GET_ALL_VENDORS = gql`
query getAllVendorsProfile($project_id: ID!) {
  getAllVendorsProfile(project_id: $project_id) {
    ${VENDOR_DATA_TO_RETRIEVE_ALL}
  }
}`;

const GET_ALL_VENDORS_DEFAULT_OPTIONS = (pId) => {
  return {
    variables: {
      project_id: pId,
    },
    pollInterval,
    fetchPolicy: 'cache-and-network',
  };
};

const GET_USER = gql`
  query getUser($id: ID!, $project_id: ID!) {
    getUser(id: $id, project_id: $project_id) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;

const GET_USER_OPTIONS = (id, projectId) => {
  return {
    variables: {
      project_id: projectId,
      id: id,
    },
  };
};

const GET_USER_INFO = gql`
  query getUserByJWT($project_id: ID!) {
    getUserByJWT(project_id: $project_id) {
      ${USER_BY_JWT_DATA}
    }
  }
`;

const GET_USER_INFO_DEFAULT_OPTIONS = (pId, skipCondition) => {
  return {
    variables: {
      project_id: pId,
    },
    skip: skipCondition,
  };
};

const GET_USER_INFO_DEFAULT_OPTIONS_V2 = (pId, skipCondition) => {
  return {
    variables: {
      project_id: pId,
    },
    skip: skipCondition,
    fetchPolicy: 'network-only',
  };
};

const ACTIVATE_MYHOME_MUTATION = gql`
mutation createClientUser($project_id :ID!,$userInput: UserInput!, $myhome_version:String!) {
  createClientUser(project_id: $project_id,  input: $userInput, myhome_version: $myhome_version) {
    ${USER_DATA_TO_RETRIEVE_ACTIVE_MY_HOME}
      }
    }
`;
const ACTIVE_MYHOME_MUTATION_DEFAULT_OPTIONS = (pId, userInput, myhome_version) => {
  return {
    variables: {
      project_id: pId,
      userInput,
      myhome_version,
    },
  };
};

const CRO_NOT_ACCEPTED = gql`
  mutation croNotAccepted($project_id: ID!, $quoteId: ID!, $expiredDate: Int) {
    croNotAccepted(project_id: $project_id, quoteId: $quoteId, expiredDate: $expiredDate)
  }
`;
const CRO_NOT_ACCEPTED_DEFAULT_OPTIONS = (project_id, quoteId, expiredDate) => {
  return {
    variables: {
      project_id,
      quoteId,
      expiredDate,
    },
  };
};

const SEND_CRO = gql`
  mutation sendCro($project_id: ID!, $quoteId: ID!, $cro: String!) {
    sendCro(project_id: $project_id, quoteId: $quoteId, cro: $cro)
  }
`;

const SEND_CRO_DEFAULT_OPTIONS = (project_id, quoteId, cro) => {
  return {
    variables: {
      quoteId,
      cro,
      project_id,
    },
  };
};

const SEND_PLAN = gql`
  mutation sendPlan($project_id: ID!, $planName: String!, $client_id: ID!, $path: String!) {
    sendPlan(project_id: $project_id, planName: $planName, client_id: $client_id, path: $path)
  }
`;

const MUTATION_LOGOUT = gql`
  mutation logout {
    logout
  }
`;

const SEND_PLAN_OPTIONS = (project_id, planName, client_id, path) => {
  return {
    variables: {
      project_id,
      planName,
      client_id,
      path,
    },
    fetchPolicy: 'no-cache',
  };
};

const GENERATE_OTP = gql`
  mutation generateOTP($project_id: ID!, $input: OTPReceive!) {
    generateOTP(project_id: $project_id, input: $input) {
      validationKey
      expirationDate
    }
  }
`;

const GENERATE_OTP_DEFAULT_OPTIONS = (project_id, where, phone = false) => {
  return {
    variables: {
      project_id,
      input: phone ? { phone: where } : { email: where },
    },
  };
};

const UPDATE_TwoFA = gql`
  mutation updateTwoFA($project_id: ID!, $id: ID!, $value: Boolean!) {
    updateTwoFA(project_id: $project_id, id: $id, value: $value) {
      ${USER_DATA_TO_RETRIEVE}
    }
  }
`;
const UPDATE_TwoFA_DEFAULT_OPTIONS = (project_id, id, value) => {
  return {
    variables: {
      project_id,
      id,
      value,
    },
  };
};

const DELETE_PROPOSAL = gql`
  mutation deleteProposal($project_id: ID!, $quoteId: ID!) {
    deleteProposal(project_id: $project_id, quoteId: $quoteId)
  }
`;

const DELETE_PROPOSAL_DEFAULT_OPTIONS = (project_id, quoteId) => {
  return {
    variables: {
      project_id,
      quoteId,
    },
  };
};

const RENEW_CLIENT_RESERVATION = gql`
  mutation renewClientReservation($project_id: ID!, $id: ID!, $enableReservation: Boolean) {
    renewClientReservation(project_id: $project_id, id: $id, enableReservation: $enableReservation){
      ${CLIENT_DATA_TO_RETRIEVE}
    }
  }
`;

const RENEW_CLIENT_RESERVATION_DEFAULT_OPTIONS = (project_id, id, enableReservation) => {
  return {
    variables: {
      project_id,
      id,
      enableReservation,
    },
  };
};

const GET_VIDEOCALL_LINK = gql`
  query getVideocallLink($project_id: ID, $clientId: ID, $roomId: String, $startDate: String, $endDate: String) {
    getVideocallLink(project_id: $project_id, clientId: $clientId, roomId: $roomId, startDate: $startDate, endDate: $endDate)
  }
`;

const GET_VIDEOCALL_LINK_DEFAULT_OPTIONS = (pId, clientId, roomId, startDate, endDate) => {
  return {
    variables: {
      project_id: pId,
      clientId,
      roomId,
      startDate,
      endDate,
    },
  };
};

const CREATE_QUOTE = gql`
  mutation createQuotes($project_id: ID!, $quoteInput: QuoteInput!) {
    createQuotes(project_id: $project_id, input: $quoteInput) {
      id
      errorMessage
    }
  }
`;

const CREATE_QUOTE_OPTIONS = (project_id, quoteInput) => {
  return {
    variables: {
      project_id,
      quoteInput,
    },
    fetchPolicy: 'no-cache',
  };
};

const UPDATE_CUSTOMIZATION = gql`
  mutation updateCustomizationStatus($project_id: ID!, $quoteId: ID!, $configurationNumber: String!, $status: String!) {
    updateCustomizationStatus(project_id: $project_id, quoteId: $quoteId, configurationNumber: $configurationNumber, status: $status) {
      customization {
        status
      }
    }
  }
`;

const UPDATE_CUSTOMIZATION_OPTIONS = (project_id, quoteId, configurationNumber, status = SALVATO) => {
  return {
    variables: {
      project_id,
      quoteId,
      configurationNumber,
      status,
    },
    fetchPolicy: 'no-cache',
  };
};

const GET_MODELS = gql`
  query getModels($project_id: ID!) {
    getModels(project_id: $project_id) {
      id
      name
    }
  }
`;

const GENERATE_QUOTE_PDF = gql`
  mutation generateQuotePdf($project_id: ID!, $quoteId: ID!, $language: String) {
    generateQuotePdf(project_id: $project_id, quoteId: $quoteId, language: $language)
  }
`;

const GENERATE_QUOTE_PDF_OPTIONS = (projectId, quoteId, language) => {
  return {
    variables: {
      project_id: projectId,
      quoteId,
      language,
    },
  };
};

const Client = {
  GET_EXPORT_CONFIGURATION,
  GET_EXPORT_CONFIGURATION_DEFAULT_OPTIONS,
  GET_EXPORT_CONFIGURATION_TEMPLATE,
  GET_EXPORT_CONFIGURATION_TEMPLATE_DEFAULT_OPTIONS,
  IMPORT_CONFIGURATION_EXCEL,
  IMPORT_CONFIGURATION_EXCEL_DEFAULT_OPTIONS,
  GET_ALL_APPARTMENTS,
  GET_ALL_APPARTMENTS_DEFAULT_OPTIONS,
  GET_ALL_APPARTMENTS_NETWORK_OPTIONS,
  GET_ALL_CLIENTS,
  GET_ALL_CLIENTS_UPDATE,
  GET_ALL_CLIENTS_DEFAULT_OPTIONS,
  GET_ALL_CLIENTS_WITH_LEAD_SCORE,
  GET_CLIENTS_DATA,
  GET_CLIENTS_DATA_DEFAULT_OPTIONS,
  GET_CLIENT,
  GET_CLIENT_DEFAULT_OPTIONS,
  GET_TYPOLOGIES,
  GET_TYPOLOGIES_DEFAULT_OPTIONS,
  INSERT_CLIENT_INTEREST,
  INSERT_CLIENT_INTEREST_DEFAULT_OPTIONS,
  INSERT_CLIENT_APPARTMENT,
  INSERT_CLIENT_APPARTMENT_DEFAULT_OPTIONS,
  INSERT_CLIENT_FEATURE,
  INSERT_CLIENT_FEATURE_DEFAULT_OPTIONS,
  REMOVE_CLIENT_INTEREST,
  REMOVE_CLIENT_INTEREST_DEFAULT_OPTIONS,
  REMOVE_CLIENT_FEATURE,
  REMOVE_CLIENT_FEATURE_DEFAULT_OPTIONS,
  UPDATE_CLIENT_FEATURE,
  UPDATE_CLIENT_FEATURE_DEFAULT_OPTIONS,
  REMOVE_CLIENT_APPARTMENT,
  REMOVE_CLIENT_APPARTMENT_DEFAULT_OPTIONS,
  UPDATE_APPARTEMENT,
  UPDATE_APPARTEMENT_DEFAULT_OPTIONS,
  UPDATE_CLIENT,
  UPDATE_CLIENT_DEFAULT_OPTIONS,
  CREATE_CLIENT,
  CREATE_CLIENT_DEFAULT_OPTIONS,
  GET_VENDOR,
  GET_PROJECT_INFO,
  GET_PROJECT_INFO_DEFAULT_OPTIONS,
  DELETE_CLIENT,
  DELETE_CLIENT_DEFAULT_OPTIONS,
  LOGIN,
  LOGIN_DEFAULT_OPTIONS,
  GET_PROJECT_INFORMATIONS,
  GET_PROJECT_INFORMATIONS_DEFAULT_OPTIONS,
  GET_PROJECT_CONFIG,
  GET_PROJECT_CONFIG_DEFAULT_OPTIONS,
  UPDATE_PROJECT_CONFIG,
  UPDATE_PROJECT_CONFIG_DEFAULT_OPTIONS,
  GET_ALL_CALENDARS,
  GET_ALL_CALENDARS_DEFAULT_OPTIONS,
  GET_ALL_CALENDARS_FO,
  GET_ALL_CALENDARS_FO_DEFAULT_OPTIONS,
  GET_ALL_CALENDARS_BY_VENDORS,
  GET_ALL_CALENDARS_BY_VENDORS_DEFAULT_OPTIONS,
  GET_CALENDAR,
  GET_CALENDAR_DEFAULT_OPTIONS,
  CREATE_CALENDAR,
  CREATE_CALENDAR_DEFAULT_OPTIONS,
  CREATE_CALENDAR_LIST,
  CREATE_CALENDAR_LIST_DEFAULT_OPTIONS,
  UPDATE_CALENDAR,
  UPDATE_CALENDAR_DEFAULT_OPTIONS,
  DELETE_CALENDAR,
  DELETE_AND_REASSIGN_CALENDAR,
  DELETE_CALENDAR_DEFAULT_OPTIONS,
  IS_FIRST_CALENDAR,
  IS_FIRST_CALENDAR_DEFAULT_OPTIONS,
  IMPORT_APARTMENT_EXCEL,
  IMPORT_APARTMENT_EXCEL_DEFAULT_OPTIONS,
  IMPORT_CLIENTS_EXCEL,
  IMPORT_CLIENTS_EXCEL_DEFAULT_OPTIONS,
  GET_QUOTES_V2,
  GET_QUOTES_V2_DEFAULT_OPTIONS,
  GET_ALL_FLOORS,
  GET_QUOTES_MANAGER,
  GET_QUOTES_MANAGER_DEFAULT_OPTIONS,
  GET_QUOTE_DOCUMENT,
  GET_QUOTE_DOCUMENT_DEFAULT_OPTIONS,
  GET_QUOTE_DOCUMENT_V2,
  GET_QUOTE_DOCUMENT_V2_DEFAULT_OPTIONS,
  UPLOAD_DOCUMENTS,
  UPLOAD_DOCUMENTS_DEFAULT_OPTIONS,
  GET_CLIENT_DOCUMENT,
  GET_CLIENT_DOCUMENT_DEFAULT_OPTIONS,
  GET_QUOTES_BYAPARTMENT,
  GET_QUOTES_BYAPARTMENT_DEFAULT_OPTIONS,
  GET_QUOTES_BYCLIENT,
  GET_QUOTES_BYCLIENT_DEFAULT_OPTIONS,
  UPDATE_EXPIRED_QUOTE,
  UPDATE_EXPIRED_QUOTE_DEFAULT_OPTIONS,
  UPDATE_RESERVED_QUOTE,
  UPDATE_RESERVED_QUOTE_DEFAULT_OPTIONS,
  UPDATE_QUOTE_PROPOSAL,
  UPDATE_QUOTE_PROPOSAL_DEFAULT_OPTIONS,
  GET_IMPORT_APPARTMENT,
  GET_IMPORT_APPARTMENT_DEAFULT_OPTIONS,
  GET_EXPORT_APPARTMENT,
  GET_EXPORT_APPARTMENT_DEAFULT_OPTIONS,
  GET_ALL_CLIENTS_ADDITIONAL_INFO,
  GET_ALL_CLIENTS_ADDITIONAL_INFO_DEFAULT_OPTIONS,
  UPDATE_ADDITIONAL_INFO,
  UPDATE_ADDITIONAL_INFO_DEFAULT_OPTIONS,
  DELETE_QUOTE,
  DELETE_QUOTE_DEFAULT_OPTIONS,
  GET_ALL_VENDORS,
  GET_ALL_VENDORS_DEFAULT_OPTIONS,
  GET_ALL_USERS,
  GET_ALL_USERS_DEFAULT_OPTIONS,
  UPDATE_USER,
  UPDATE_USER_DEFAULT_OPTIONS,
  UPDATE_USER_BY_ACCOUNT_MANAGER,
  UPDATE_USER_BY_ACCOUNT_MANAGER_DEFAULT_OPTIONS,
  DELETE_USER,
  DELETE_USER_DEFAULT_OPTIONS,
  CREATE_USER_MANAGER,
  CREATE_USER_DEFAULT_OPTIONS,
  CONFIRM_PASSWORD_USER,
  CONFIRM_PASSWORD_USER_DEFAULT_OPTIONS,
  CREATE_ADDITIONAL_INFO,
  CREATE_ADDITIONAL_INFO_DEFAULT_OPTIONS,
  DELETE_ADDITIONAL_INFO,
  DELETE_ADDITIONAL_INFO_DEFAULT_OPTIONS,
  GET_USER,
  GET_USER_OPTIONS,
  GET_USER_INFO,
  GET_USER_INFO_DEFAULT_OPTIONS,
  GET_USER_INFO_DEFAULT_OPTIONS_V2,
  ACTIVATE_MYHOME_MUTATION,
  ACTIVE_MYHOME_MUTATION_DEFAULT_OPTIONS,
  DELETE_MY_HOME,
  DELETE_MY_HOME_DEFAULT_OPTIONS,
  DELETE_CALENDAR_LIST,
  DELETE_CALENDAR_LIST_DEFAULT_OPTIONS,
  GET_PROJECT_FEATURES,
  GET_PROJECT_FEATURES_DEFAULT_OPTIONS,
  GET_PROJECT_FEATURES_NETWORK_OPTIONS,
  UPDATE_PERTINENZA_INFO,
  UPDATE_PERTINENZA_INFO_DEFAULT_OPTIONS,
  UPLOAD_APARTEMENT_PLANIMETRY,
  UPLOAD_APARTEMENT_PLANIMETRY_DEFAULT_OPTIONS,
  GET_APARTMENTS_PROMO_PRICE,
  GET_APARTMENTS_PROMO_PRICE_DEFAULT_OPTIONS,
  GET_PROJECT_INFORMATIONS_APPOINTMENT,
  GET_PROJECT_INFORMATIONS_APPOINTMENT_DEFAULT_OPTIONS,
  GET_QUOTES_BYCLIENT_NETWORK_OPTIONS,
  SEND_CRO,
  SEND_CRO_DEFAULT_OPTIONS,
  SEND_PLAN,
  SEND_PLAN_OPTIONS,
  CRO_NOT_ACCEPTED,
  CRO_NOT_ACCEPTED_DEFAULT_OPTIONS,
  PROJECTID_GENERIC_OPTION_NOCACHE,
  DELETE_DOCUMENT,
  DELETE_DOCUMENT_DEFAULT_OPTIONS,
  GENERATE_OTP,
  GENERATE_OTP_DEFAULT_OPTIONS,
  UPDATE_TwoFA,
  UPDATE_TwoFA_DEFAULT_OPTIONS,
  UPDATE_USER_COLOR,
  UPDATE_USER_COLOR_DEFAULT_OPTIONS,
  UPDATE_DOCUMENT,
  UPDATE_DOCUMENT_DEFAULT_OPTIONS,
  DELETE_PROPOSAL,
  DELETE_PROPOSAL_DEFAULT_OPTIONS,
  MUTATION_LOGOUT,
  RENEW_CLIENT_RESERVATION,
  RENEW_CLIENT_RESERVATION_DEFAULT_OPTIONS,
  GET_VIDEOCALL_LINK,
  GET_VIDEOCALL_LINK_DEFAULT_OPTIONS,
  CREATE_ACTION,
  CREATE_ACTION_DEFAULT_OPTIONS,
  GET_CLIENT_ADDITIONAL_INFO_BY_NAME,
  GET_CLIENT_ADDITIONAL_INFO_BY_NAME_DEFAULT_OPTIONS,
  UPDATE_CLIENT_TAGS,
  UPDATE_CLIENT_TAGS_DEFAULT_OPTIONS,
  CREATE_QUOTE,
  CREATE_QUOTE_OPTIONS,
  UPDATE_CUSTOMIZATION,
  UPDATE_CUSTOMIZATION_OPTIONS,
  GET_BACK_OFFICE_USERS,
  GET_BACK_OFFICE_USERS_DEFAULT_OPTIONS,
  GET_MODELS,
  GENERATE_QUOTE_PDF,
  GENERATE_QUOTE_PDF_OPTIONS,
};

export default Client;
