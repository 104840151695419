import React, { useEffect } from 'react';

import { useMutation } from '@apollo/client';
import { useTranslation } from '@tecma/i18n';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Route, Redirect } from 'react-router-dom';

import Client from 'client/Client';
import { useStore } from 'store/storeUtils';

import PageTemplate from 'pages/PageTemplate';

import CrmSnackbarAmber from 'components/common/CrmSnackbarAmber';
import CrmSnackbarError from 'components/common/CrmSnackbarError';
import CrmSnackbarFeedback from 'components/common/CrmSnackbarFeedback';

import convertLanguage from 'utils/convertLanguage';

interface Props {
  exact?: boolean;
  path: string;
  ToRender: React.ElementType;
  title: string;
  goBack?: boolean;
  navbar?: boolean;
  breadcrumb?: string;
}

const PrivateRoute = observer(({ exact = false, path, ToRender, title, goBack = false, navbar }: Props) => {
  const [updateUser] = useMutation(Client.UPDATE_USER);
  const { i18n, t } = useTranslation();
  const store = useStore();
  const [newLang, setNewLang] = React.useState('');

  const setLanguage = (lang, locale) => {
    setNewLang(lang);
    // @ts-ignore
    const input = { email: store.loggedUser.email, language: lang, locale: locale, project_ids: store.loggedUser.project_ids };
    // @ts-ignore
    const oldLanguage = store.loggedUser && store.loggedUser.language;
    // @ts-ignore
    const oldLocale = store.loggedUser && store.loggedUser.locale;
    // @ts-ignore
    updateUser(Client.UPDATE_USER_DEFAULT_OPTIONS(store.loggedUser.id, input))
      .then(() => {
        store.setSnackbar(true, t('snackbar.successOperation'));
        store.setSystemLanguage(lang);
        store.setLoggedUserLang(lang, locale);
        i18n.changeLanguage(convertLanguage(lang, locale));
      })
      .catch((e) => {
        if (oldLanguage) {
          setNewLang(oldLanguage);
          store.setLoggedUserLang(oldLanguage, oldLocale);
          store.setSystemLanguage(oldLanguage);
          i18n.changeLanguage(convertLanguage(oldLanguage, oldLocale));
        }
        store.setSnackbarError(true, t('snackbar.failedOperation'));
        console.error(e);
      });
  };

  const render = (props) => {
    if (store.loggedUser) {
      return <PageTemplate BodyComponent={ToRender} title={title} goBack={goBack} setLanguage={setLanguage} {...props} />;
    }
    if (!store.baseUrl) return null;

    // @ts-ignore
    return <Redirect exact to={{ pathname: '/login', search: window.location.search }} />;
  };

  useEffect(() => {
    moment.locale(newLang);
  }, [newLang]);

  return (
    <>
      <CrmSnackbarFeedback />
      <CrmSnackbarAmber />
      <CrmSnackbarError />
      {/* @ts-ignore */}
      <Route
        exact={exact}
        path={path}
        // @ts-ignore
        navbar={navbar}
        render={render}
      />
    </>
  );
});

export default PrivateRoute;
