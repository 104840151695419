import { memo } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Button as ButtonTecma } from '@tecma/ds';

import './ButtonSwitchVersion.style.scss';

interface Props {
  onClick: () => void;
  label: string;
}

export const ButtonSwitchVersion = memo(({ onClick, label }: Props) => {
  const matchesMD = useMediaQuery('(max-width:959px)', { noSsr: true });

  return (
    <ButtonTecma className='switch-interface-btn' onClick={onClick} iconName='switch-horizontal' outlined>
      {!matchesMD && label}
    </ButtonTecma>
  );
});
