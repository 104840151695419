import React, { memo } from 'react';

import { SearchBar } from '@tecma/ds';
import { useTranslation } from '@tecma/i18n';

import { useModuleClients } from '../../hooks/useModuleClients';

import './Toolbar.style.scss';

interface Props {
  defaultValue: string;
  project_id: string;
}

export const Toolbar = memo(({ defaultValue = '', project_id }: Props) => {
  const { t } = useTranslation();
  const { onSearch, onCancel, loading } = useModuleClients(project_id);

  const options = {
    maxLength: {
      value: 400,
      message: t('toolbar.searchBar.error.maxLength', { maxLength: 400 }),
    },
  };

  return (
    <div className='tecma-toolbar'>
      <SearchBar
        label={t('toolbar.searchBar.label')}
        placeholder={t('toolbar.searchBar.placeholder')}
        options={options}
        defaultValue={defaultValue}
        onSearch={onSearch}
        onCancel={onCancel}
        isLoading={loading}
        searchButtonText={t('toolbar.searchBar.searchButtonText')}
        cancelButtonText={t('toolbar.searchBar.cancelButtonText')}
      />
    </div>
  );
});
