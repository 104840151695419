import React, { lazy, Suspense, useEffect, useState } from 'react';

import { useQuery, useLazyQuery } from '@apollo/client';
import { datadogRum } from '@datadog/browser-rum';
import { setBaseUrl } from '@tecma/bucket-lib';
import { initI18n, useTranslation, loadResources } from '@tecma/i18n';
import moment from 'moment';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Client from 'client/Client';
import SSOComponent from 'client/SSOComponent';
import PrivateRoute from 'routes/PrivateRoute';
import { SessionStore } from 'SessionStore';
import { useStore } from 'store/storeUtils';

import AggiornaConfermaPassword from 'pages/AggiornaConfermaPassword';

import CrmLoader from 'components/common/CrmLoader';
import LogoutListener from 'components/specific/LogoutListener';

import inizializeAnalytics from 'utils/analytics.js';
import convertLanguage from 'utils/convertLanguage.jsx';
import DetectUrl from 'utils/DetectUrl';
import JwtChecker from 'utils/JwtChecker.js';

import { PERMISSION } from 'constants/PagePermission';

import { IS_CLIENTS_V2_ACTIVE } from './customHooks/useModuleClients';
import translation from './i18n/translation/en/translation.json';
import { V2_PageClienti } from './pages/@v2/clienti/page';
import { ProviderRouter } from './routes/Provider';
import RouteConsts from './routes/Routes';

// #region ::: CONFIGURATION
if (process.env.REACT_APP_NAMESPACE === 'biz-tecma-prod') {
  datadogRum.init({
    applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID || '',
    clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN || '',
    site: 'datadoghq.eu',
    service: 'followup-sell',
    env: process.env.REACT_APP_NAMESPACE,
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  });
}

export const i18n = initI18n(translation, {
  bucketBase: `${process.env.REACT_APP_BUCKET_BASEURL}`,
  productName: 'FollowUp',
  apiBase: process.env.REACT_APP_API_URI || '',
  expirationTime: 1000 * 60, // 1 minuto,
  cache: 'no-cache',
});

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_CODE,
};

inizializeAnalytics(tagManagerArgs);
// #endregion

export const App = () => {
  const store = useStore();
  const [loading, setLoading] = useState(true);
  const [i18nLoading, seti18nLoading] = useState(true);
  const prjInfo = useQuery(Client.GET_PROJECT_INFO, Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl()));

  const getProject = async () => {
    if (prjInfo && prjInfo.data) {
      const project = { ...prjInfo.data.getProjectInfoByHost };
      store.setAssetsByObject(project);
      store.setDefaultLanguage(project.defaultLang);
      setLoading(false);
      await loadResources({
        id: project.id,
        displayName: project.displayName,
        languages: store.configLanguages,
      });
      seti18nLoading(false);
    }
  };

  useEffect(() => {
    getProject();
  }, [prjInfo]);

  if (loading || i18nLoading) return <CrmLoader hasBackdrop={false} loading={true} z={true} />;

  return (
    <div className='wrapper'>
      <JwtChecker />
      <LayoutRoot />
    </div>
  );
};

export const LayoutRoot = () => {
  const store = useStore();
  const { i18n } = useTranslation();
  const [jwtLoading, setJwtLoading] = useState(true);
  const prjInfo = useQuery(Client.GET_PROJECT_INFO, Client.GET_PROJECT_INFO_DEFAULT_OPTIONS(DetectUrl()));
  const skipCondition = window.location.pathname.includes('resetPassword');
  const [loadUserInfo, userInfo] = useLazyQuery(Client.GET_USER_INFO);

  const firstRoute = RouteConsts.sort((a, b) => a.order - b.order).find((el) => {
    const page = PERMISSION[el.id];
    // @ts-ignore
    const pagePermission = !page || (page && store.loggedUser && page.includes(store.loggedUser.role));
    if (pagePermission && el.id === 'home') return el;
    else if (pagePermission && el.navbar) return el;
  });

  if (prjInfo && !prjInfo.loading && prjInfo.called && prjInfo.data) {
    if (prjInfo.data.getProjectInfoByHost === null) throw new Error('Missing project info data');
    else {
      store.setAssetsByObject({ ...prjInfo.data.getProjectInfoByHost });
      store.setBaseUrl(prjInfo.data.getProjectInfoByHost.baseurl);
      setBaseUrl(prjInfo.data.getProjectInfoByHost.baseurl || '');
      // @ts-ignore
      document.title = store.pageTitles.followup;
    }
  }

  useEffect(() => {
    if (userInfo && userInfo.data && userInfo.data.getUserByJWT) {
      setJwtLoading(false);
      const userLanguage = userInfo?.data?.getUserByJWT?.language;
      const userLocale = userInfo?.data?.getUserByJWT?.locale;
      store.setLoggedUserJwtData({ userData: userInfo.data.getUserByJWT, checkingJwt: false });
      store.setSystemLanguage(userLanguage);
      // @ts-ignore
      if (i18n.language.split('-')[0] !== (store.loggedUser.language || userLanguage) && userLanguage) {
        const convertedLanguage = convertLanguage(userLanguage, userLocale);
        i18n.changeLanguage(convertedLanguage);
        moment.locale(convertedLanguage);
      }
    } else if (userInfo?.error) {
      setJwtLoading(false);
    }
  }, [userInfo]);

  useEffect(() => {
    store.setCheckingJwt(true);
    if (store.projectId && !userInfo.called && !skipCondition) {
      loadUserInfo(Client.GET_USER_INFO_DEFAULT_OPTIONS(store.projectId, skipCondition));
    }
  }, [store.projectId]);

  if (!store.baseUrl) return <h1>Inserire un&apos;iniziativa</h1>;
  if (jwtLoading) return <CrmLoader hasBackdrop={false} loading={true} z={true} />;

  return (
    <SessionStore>
      <Router basename={store.baseUrl}>
        <ProviderRouter>
          <Switch>
            <Route exact path='/resetPassword' component={AggiornaConfermaPassword} />
            <Route exact path='/login' component={SSOComponent} />
            {RouteConsts.sort((a, b) => a.order - b.order).map((el) => {
              const page = PERMISSION[el.id];
              // @ts-ignore
              const pagePermission = !page || (page && store.loggedUser && page.includes(store.loggedUser.role));
              return (
                <PrivateRoute
                  // @ts-ignore
                  breadcrumb={el.breadcrumb}
                  exact={el.exact}
                  key={el.order}
                  path={el.to}
                  ToRender={el.component}
                  title={el.title}
                  goBack={el.goBack}
                  navbar={
                    store.enabledSections && store.enabledSections.length > 0
                      ? // @ts-ignore
                        store.enabledSections.includes(el.id) && pagePermission
                      : el.navbar && pagePermission
                  }
                />
              );
            })}
            {IS_CLIENTS_V2_ACTIVE && <PrivateRoute exact path='/v2/clienti' ToRender={V2_PageClienti} title='title.clientManager' navbar />}
            <PrivateRoute
              // @ts-ignore
              path={firstRoute && firstRoute.to}
              // @ts-ignore
              ToRender={firstRoute && firstRoute.component}
              // @ts-ignore
              title={firstRoute && firstRoute.title}
              goBack={false}
            />
          </Switch>
        </ProviderRouter>
        <LogoutListener />
      </Router>
    </SessionStore>
  );
};
