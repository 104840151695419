import React, { useEffect } from 'react';

import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { useStore } from 'store/storeUtils';

import { useModuleClients } from '../../customHooks/useModuleClients';

const LogoutListener = observer(() => {
  const store = useStore();
  const history = useHistory();
  const { onLogout } = useModuleClients('v2');

  const clearStore = () => {
    try {
      onLogout();
      store.logOut();
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (store.redirectionToSsoNeeded) {
      clearStore();
      history.push('/login');
    }
  }, [store.redirectionToSsoNeeded]);

  return <></>;
});

export default LogoutListener;
