import { useEffect } from 'react';

import { useHistory } from 'react-router-dom';

import RouteConsts from './Routes';

export const ProviderRouter = ({ children }) => {
  const history = useHistory();
  const { pathname } = history.location;
  const moduleClientsVersion = localStorage.getItem('module-clients');
  const isLegacyMode = moduleClientsVersion === 'legacy';
  const pathClientList = isLegacyMode ? '/clienti' : '/v2/clienti';

  useEffect(() => {
    console.log({ pathname });

    const routesNavbar = RouteConsts.filter((route) => route.navbar);
    const isClientDetailPage = pathname.includes('scheda-cliente');
    const isClientListV2 = pathname.includes('v2/clienti');
    const isNavbarRoute = !!routesNavbar.find((route) => pathname.includes(route.to));
    const isCalendarDetailPage = pathname.includes('/new-event');
    const isClientCreatePage = pathname.includes('/aggiungi-cliente');
    const isPertinenzaPage = pathname.includes('/scheda-pertinenza');
    const isApartmentDetailPage = pathname.includes('/scheda-appartamento');
    const isCalendarioDetailPage = pathname.includes('/dettagli-calendario');
    const isCalendarioUpdatePage = pathname.includes('/update-event');
    const isValidRoute = isNavbarRoute || isClientDetailPage || isClientListV2;
    const isClientUpdatePage = pathname.includes('/modifica-cliente');
    const idClient = pathname.split('/modifica-cliente')[1];

    if (isCalendarDetailPage || isCalendarioDetailPage || isCalendarioUpdatePage) return history.replace('/calendario');
    if (isClientUpdatePage) return history.replace(`/scheda-cliente${idClient}`);
    if (isClientCreatePage) return history.replace(pathClientList);
    if (isPertinenzaPage || isApartmentDetailPage) return history.replace('/appartamenti');
    else if (!isValidRoute) return history.push('/');
  }, []);

  return children;
};
