import React from 'react';

import CheckIcon from '@material-ui/icons/Check';
import { observer } from 'mobx-react';

import { useStore } from 'store/storeUtils';

import CrmSnackbar from 'components/common/CrmSnackbar';

const CrmSnackbarFeedback = observer(() => {
  const store = useStore();

  return (
    <CrmSnackbar open={store.snackbarOpen} onClose={() => store.setSnackbar(false)}>
      <div className='snackbar-body'>
        <CheckIcon style={{ marginRight: '5px' }} />
        {store.snackbarMessage}
      </div>
    </CrmSnackbar>
  );
});

export default CrmSnackbarFeedback;
