import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useTranslation } from '@tecma/i18n';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import RouteConsts, { RouteClients_V2 } from 'routes/Routes';
import { useStore } from 'store/storeUtils';

import CrmSvgLoader from 'components/common/CrmSvgLoader';

import convertLanguage from 'utils/convertLanguage';
import getOrderedLanguages from 'utils/getOrderedLanguages';

import { PERMISSION } from 'constants/PagePermission';

import packageJSON from '../../../package.json';
import { useModuleClients } from '../../customHooks/useModuleClients';

import ChartSquareIcon from 'images/assets/chart-square-bar.svg';
import ExternalLinkIcon from 'images/assets/external-link.svg';
import LogoTecma from 'images/assets/tecma_logo.svg';
import 'styles/navbar.scss';

const useNavbarRoutes = () => {
  const store = useStore();
  const navbarRoutes =
    RouteConsts &&
    RouteConsts.filter((e) => {
      const page = PERMISSION[e.id];
      // @ts-ignore
      const pagePermission = !page || (page && store.loggedUser && page.includes(store.loggedUser.role));
      return store.enabledSections && store.enabledSections.length > 0
        ? // @ts-ignore
          store.enabledSections.includes(e.id) && e.navbar && pagePermission
        : e.navbar && pagePermission;
    });
  return navbarRoutes;
};

export const Sidebar = observer((props) => {
  const { setLanguage } = props;
  const history = useHistory();
  const store = useStore();
  const { t } = useTranslation();
  const { isLegacyMode, isClientsV2Active } = useModuleClients('v2');
  const navbarRoutes = useNavbarRoutes();
  const [orderedLanguages, setOrderedLanguages] = useState(
    // @ts-ignore
    getOrderedLanguages(store.configLanguages, convertLanguage(store.loggedUser.language, store.loggedUser.locale)),
  );
  const { location } = useHistory();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const matchesPhone = useMediaQuery(theme.breakpoints.down('xs'), {
    noSsr: true,
  });
  const logo = `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${store.loginProjectName}/global/img/primary-logo.svg`;
  const logoSmall = `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${store.loginProjectName}/global/img/primary-logo--XS.svg`;
  // @ts-ignore
  const tuning = store.enabledTools?.find((tool) => tool.name === 'Tuning' && tool.enabled);

  const handleClick = () => {
    store.toggleMenuMobile();
    store.setOpenDrawer(!store.openDrawer);
  };

  const handleClose = (val) => {
    store.closeMenuMobile();
    if (typeof val !== 'undefined' && typeof val === 'number') goTo(val);
    if (matchesPhone) store.setOpenDrawer(!store.openDrawer);
  };

  const goHome = () => {
    store.closeMenuMobile();
    goTo(0);
  };

  const goTo = (val) => {
    // store.updateCurrentNavTab(val, navbarRoutes[val].to);
    const formattedRoute =
      navbarRoutes[val].to === '/clienti' && isClientsV2Active
        ? isLegacyMode
          ? navbarRoutes[val].to
          : RouteClients_V2.to
        : navbarRoutes[val].to;

    history.push(formattedRoute);
    if (navbarRoutes[val].to !== '/appartamenti' && store.tabValueApartment === 1) store.setTabValueApartment(0);

    // @ts-ignore
    if (store.calendarFilters?.filteredVendor?.length > 0 || store.calendarFilters?.filteredClient?.length > 0) {
      store.setCalendarFilters({ filteredVendor: [], filteredClient: [] });
    }
  };

  const handleChangeLanguage = (selectedLanguage) => {
    setOrderedLanguages(getOrderedLanguages(store.configLanguages, selectedLanguage));
    const trueLanguage = selectedLanguage.split('-')[0];
    const locale = selectedLanguage.split('-')[1];
    setLanguage(trueLanguage, locale);
    store.setOpenDrawer(!store.openDrawer);
  };

  if (typeof store.openDrawer === 'undefined') {
    if (matches) {
      store.setOpenDrawer(false);
    } else {
      store.setOpenDrawer(true);
    }
  }

  const addDefaultSrc = (ev, url) => {
    if (ev.target.src.includes(process.env.REACT_APP_BUCKET_BASEURL)) {
      ev.target.src = url && url.length > 0 && url[0];
    } else {
      ev.target.hidden = true;
    }
  };
  const openTuning = () => window.open(tuning.url, '_blank');
  const drawer = (
    <>
      {store.openDrawer || matchesPhone ? (
        <img
          className={matchesPhone ? 'image-drawer-mobile' : 'image-drawer'}
          alt='logo'
          src={logo}
          onClick={goHome}
          onError={(ev) => addDefaultSrc(ev, store.logo)}
          style={{ cursor: 'pointer' }}
        />
      ) : (
        <img
          className='image-drawer-small'
          alt='logo-small'
          src={logoSmall}
          onClick={goHome}
          style={{ cursor: 'pointer' }}
          onError={(e) => {
            // @ts-ignore
            e.target.src = logo;
            // @ts-ignore
            e.target.onerror = '';
          }}
        />
      )}
      <List className={store.openDrawer ? 'navbar-list-open' : 'navbar-list-close'}>
        {navbarRoutes &&
          navbarRoutes.map((tabEl, i) => (
            <ListItem
              className={i === 0 ? 'list-item first-drawer-of-list' : 'list-item'}
              key={`tab${tabEl.order}`}
              onClick={() => handleClose(i)}
              selected={store.currentNavTab === i}
            >
              <Button
                className='drawer-button'
                disableRipple={true}
                // @ts-ignore
                startIcon={<CrmSvgLoader alt='' data={tabEl.icon} className='navbar-image-size' />}
                classes={{
                  label: 'justify-left',
                }}
              >
                {store.openDrawer && <div className='text-align-left line-height-initial'>{t(`${tabEl.navbarTitle}`)}</div>}
              </Button>
            </ListItem>
          ))}

        {tuning && (
          <ListItem className='list-item-external' button onClick={openTuning}>
            <Button
              className='drawer-button'
              disableRipple={true}
              startIcon={<CrmSvgLoader data={ChartSquareIcon} />}
              endIcon={store.openDrawer && <CrmSvgLoader data={ExternalLinkIcon} />}
              classes={{
                label: 'justify-left',
              }}
            >
              {store.openDrawer && <div className='text-align-left line-height-initial'>{t(`navbarTitle.tuning`)}</div>}
            </Button>
          </ListItem>
        )}
      </List>
      {store.openDrawer && !matchesPhone && (
        <div className='image-tecm-container'>
          <div style={{ fontSize: '11px', paddingBottom: '8px' }}>Powered by</div>
          <CrmSvgLoader data={LogoTecma} alt='Tecma' className='image-tecma' />
          <div style={{ fontSize: '11px' }}>version {packageJSON.version}</div>
        </div>
      )}
      {matchesPhone && (
        <div>
          <ToggleButtonGroup className='language-buttons'>
            {orderedLanguages.map((language, index) => {
              const trueLanguage = language ? language.split('-')[0] : language;
              const locale = language ? language.split('-')[1] : null;
              return (
                <ToggleButton
                  value={language.toUpperCase()}
                  onClick={() => {
                    handleChangeLanguage(language);
                  }}
                  // @ts-ignore
                  selected={store.loggedUser.language === trueLanguage && store.loggedUser.locale === locale}
                  style={{ borderRight: index === orderedLanguages.length - 1 ? '0px' : '2px solid #CACBD3' }}
                >
                  {language.toUpperCase()}
                </ToggleButton>
              );
            })}
          </ToggleButtonGroup>
        </div>
      )}
    </>
  );

  useEffect(() => {
    let val = navbarRoutes.findIndex((el) => el.to === location.pathname);
    if (location.pathname.includes('/v2/clienti')) val = 2;
    store.updateCurrentNavTab(val, location.pathname);
  }, [location.pathname]);

  return (
    <>
      <div className='toolbar-container'>
        <Drawer
          variant={matchesPhone ? 'persistent' : 'permanent'}
          anchor={matchesPhone ? 'right' : 'left'}
          open={store.openDrawer}
          classes={
            store.openDrawer
              ? {
                  paperAnchorLeft: 'permanent-drawer',
                  paperAnchorRight: 'permanent-drawer permanent-drawer-mobile',
                }
              : {
                  paper: 'permanent-drawer permanent-drawer-close',
                }
          }
        >
          {!matchesPhone && (
            <IconButton aria-label='more' aria-controls='long-menu' aria-haspopup='true' onClick={handleClick} className='hamburger-button'>
              <MenuIcon />
            </IconButton>
          )}
          {drawer}
        </Drawer>
      </div>
    </>
  );
});
