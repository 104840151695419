import React, { Suspense } from 'react';

import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from '@tecma/i18n';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';

import { useStore } from 'store/storeUtils';

import CrmGoBackButton from 'components/common/CrmGoBackButton';
import Header from 'components/common/CrmHeader';
import { Sidebar } from 'components/common/Sidebar';

import CrmLoader from '../components/common/CrmLoader';

import '../styles/pageTemplate.scss';

interface Props {
  breadcrumb?: string;
  BodyComponent: (props) => React.JSX.Element;
  title: string;
  goBack?: boolean;
  setLanguage: () => void;
}

const PageTemplate = observer(({ BodyComponent, title = '', goBack = false, setLanguage, ...rest }: Props) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const store = useStore();
  const { openDrawer } = store;
  const { t } = useTranslation();
  const pageName = t(title);
  const titleArray = pageName.split('/');
  const history = useHistory();
  const { pathname } = history.location;
  const showBanner = localStorage.getItem('showBanner') !== 'false';
  const isClientListV2 = pathname.includes('/v2/clienti');
  const isClientList = pathname.includes('/clienti');
  const paddingTop = (isClientListV2 || isClientList) && showBanner ? 150 : 55;

  return (
    <div className='pageTemplate'>
      <Header setLanguage={setLanguage} title={title} goBack={goBack} />
      <div className='pageTemplate-content'>
        <Sidebar setLanguage={setLanguage} />
        <div
          className={
            typeof openDrawer === 'undefined'
              ? matches === true
                ? 'body-wrapper-close'
                : 'body-wrapper-open'
              : openDrawer === true
                ? 'body-wrapper-open'
                : 'body-wrapper-close'
          }
        >
          <div className='page-title' style={{ paddingTop }}>
            {goBack && <CrmGoBackButton className='page-back-button' />}
            {titleArray.length > 1 ? (
              <>
                <span>{titleArray[0] + ' / '}</span>
                <span>{titleArray[1]}</span>
              </>
            ) : (
              <span>{titleArray[0]}</span>
            )}
          </div>
          <Suspense fallback={<CrmLoader hasBackdrop={false} loading={true} z={true} />}>
            <BodyComponent title={title} {...rest} />
          </Suspense>
        </div>
      </div>
    </div>
  );
});

export default PageTemplate;
