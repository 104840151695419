import React from 'react';

import { Tooltip } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Trans, useTranslation } from '@tecma/i18n';

import CrmSvgLoader from 'components/common/CrmSvgLoader';

import WarningIcon from 'images/assets/warning.svg';

import 'styles/textWithIcon.scss';

const TextWithIcon = ({ text, icon, showIcon, disabled, extraTextClasses = '', tooltipText = '', placement = 'top-start' }) => {
  const { t } = useTranslation();
  const useStyles = makeStyles(() =>
    createStyles({
      tooltip: {
        maxWidth: 200,
        fontSize: '0.75rem',
        backgroundColor: 'rgba(59, 59, 59, 0.90)',
      },
    }),
  );
  const classes = useStyles();
  const textTooltip = (
    <Trans
      i18nKey={tooltipText || 'client.tooltip.deactivatedAccount'}
      components={{ b: <b />, br: <br /> }}
      values={{ sectionName: t('navbarTitle.vendorManager') }}
    />
  );

  return (
    <span className={`text-with-icon ${disabled ? 'disabled' : ''}`}>
      {showIcon && (
        <Tooltip placement={placement} title={textTooltip} disableHoverListener={!disabled} classes={{ tooltip: classes.tooltip }}>
          <div>
            <CrmSvgLoader className='icon' data={icon ?? WarningIcon} />
          </div>
        </Tooltip>
      )}
      <span className={`text ${extraTextClasses}`}>{text}</span>
    </span>
  );
};

export default TextWithIcon;
