import React from 'react';

import { ApolloProvider } from '@apollo/client';
import MomentUtils from '@date-io/moment';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ErrorPage } from '@tecma/ds';
import i18n from '@tecma/i18n';
import ReactDOM from 'react-dom';
import { ErrorBoundary } from 'react-error-boundary';

import { App } from 'App';
import API from 'client/GraphqlClient';
import Store from 'store/store';
import { StoreProvider } from 'store/storeUtils';
import { CrmMUITheme } from 'styles/crmMUITheme';

import * as serviceWorker from './serviceWorker';

import 'styles/main.scss';

// @ts-ignore
const theme = createTheme(CrmMUITheme);

const store = new Store();
// @ts-ignore
document.title = store?.pageTitles?.followup ?? 'Follow Up';

ReactDOM.render(
  <React.StrictMode>
    <StoreProvider value={store}>
      <ApolloProvider client={API(store)}>
        {/* @ts-ignore */}
        <ErrorBoundary
          onError={(e) => console.error(e)}
          FallbackComponent={() => {
            return (
              <ErrorPage
                // NOTE: the check loginPrjectNAme !== undefined is REQUIRED 'cause there's a folder called 'undefined' inside the bucket.
                // @ts-ignore
                logo={
                  store.loginProjectName !== undefined &&
                  `${process.env.REACT_APP_BUCKET_BASEURL}/initiatives/${store.loginProjectName}/global/img/logo192.png`
                }
                language={store.getCurrentLanguage() ?? i18n?.language}
              />
            );
          }}
        >
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <App />
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </ApolloProvider>
    </StoreProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
// @ts-ignore
serviceWorker.register();
