import React from 'react';

import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import colors from '../../styles/colors.module.scss';

const CriteriaPasswordRow = (props) => {
  const { check, errorCriteria, criteriaLabel } = props;
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <>
        {!errorCriteria ? (
          <CheckIcon
            style={
              check
                ? {
                    width: '16px',
                    height: '16px',
                    marginRight: '10px',
                    color: colors.accent,
                  }
                : {
                    width: '16px',
                    height: '16px',
                    marginRight: '10px',
                    color: '#E8E9EB',
                  }
            }
          />
        ) : (
          <CloseIcon
            style={{
              width: '16px',
              height: '16px',
              marginRight: '10px',
              color: '#F44336',
            }}
          />
        )}

        <span style={errorCriteria ? { color: '#F44336' } : {}}>{criteriaLabel}</span>
      </>
    </div>
  );
};

CriteriaPasswordRow.propTypes = {
  check: PropTypes.bool,
  errorCriteria: PropTypes.bool,
  criteriaLabel: PropTypes.string,
};

export default CriteriaPasswordRow;
