import React from 'react';

import { Alert, Button } from '@tecma/ds';

import './style.scss';

interface Props {
  onSwitch: () => void;
  onDismiss: () => void;
  title: string;
  description: string;
  buttonLabel: string;
  ref?: React.LegacyRef<HTMLDivElement>;
}

export const Banner = React.forwardRef(
  ({ title, description, onDismiss, onSwitch, buttonLabel }: Props, ref: React.LegacyRef<HTMLDivElement>) => {
    if (process.env.REACT_APP_FEATURE_FLAG_CLIENTS_V2 !== 'true') return null;
    return (
      <div
        style={{
          position: 'absolute',
          top: 0,
          // NOTE: This fix main wrapper that has padding 15px
          left: -15,
          width: 'calc(100% + 30px)',
        }}
        ref={ref}
      >
        <Alert
          className='tecma-banner'
          type='primary'
          title={title}
          description={description}
          onDismiss={onDismiss}
          dismissable
          contentDirection='horizontal'
          // contentDirection={device.type === 'mobile' ? 'horizontal' : 'vertical'}
          actions={
            <Button onClick={onSwitch} link>
              <span>{buttonLabel}</span>
            </Button>
          }
        />
      </div>
    );
  },
);
