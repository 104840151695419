import { useEffect } from 'react';

import { gql, useQuery } from '@apollo/client';
import { observer } from 'mobx-react';

import { useProjectID } from 'store/hooks/useProjectID';
import { useStore } from 'store/storeUtils';

import { utilityGetDateWithTimezone } from 'utils/getDateWithTimezone';

const queryClientsAll = gql`
  query getAllClients($project_id: ID!, $getLeadScore: Boolean = false) {
    getAllClientsFollowUp(project_id: $project_id, getLeadScore: $getLeadScore) {
      id
      firstName
      lastName
      status
      avatar
      nAppuntamenti
      nPreventiviRichiesti
      email
      tel
      city
      birthdate
      trattamento
      marketing
      profilazione
      note
      interested_appartments {
        appartment
        status
      }
      selected_appartments {
        appartment
        status
      }
      createdOn
      updatedOn
      coniuge {
        nome
        cognome
        mail
        indirizzo
        tel
      }
      info {
        source
        motivazione
        tempo_ricerca
        mutuo
        leva
        arredo
        date_lead
        source
        budget
        motivazione
        tempo_ricerca
        stato_civile
        caratteristiche
        mutuo
        leva
        arredo
        alternative
        prima_casa
        family {
          adulti
          bambini
          animali
        }
      }
      myhome_version
      user
      appuntamenti {
        id
        startDate
        typology
        vendor {
          id
        }
      }
      additionalInfo
      reserved
      confirmed
      privacyInformation
      myhomeStatus
      lastAccess
      dateOperationFailed
      actions {
        _id
        actionName
        actionDate
        category
        vendor
        quote
        note
        deleted
        createdOn
      }
      leadScore
      nAccesses
      nReserved
      nProposals
      motivazione
      appointee {
        id
        firstName
        lastName
        email
        role
        isDisabled
      }
    }
  }
`;

export const SessionStore = observer(({ children }) => {
  const projectID = useProjectID();
  const store = useStore();
  const cachedVersion = localStorage.getItem('module-clients');

  const { refetch } = useQuery(queryClientsAll, {
    variables: {
      project_id: projectID,
    },
    fetchPolicy: 'cache-and-network',
    skip: true,
  });

  async function loadGlobalData() {
    const zuluDate = new Date();
    const updatedAfter = utilityGetDateWithTimezone(new Date(zuluDate));
    store.setLoadingClients(true);
    const allEntities = await refetch();

    const clientList = allEntities.data.getAllClientsFollowUp;
    store.setProjectClients(projectID, clientList, updatedAfter);
    store.setClients(clientList);

    store.setLoadingClients(false);
  }

  useEffect(() => {
    if (cachedVersion === 'legacy') loadGlobalData();
  }, []);

  return <>{children}</>;
});
