import { gql } from '@apollo/client';

import { Client, ClientCRM, ClientMyHome } from '../declarations/common';

interface Pagination {
  nextPage: number;
  page: number;
  perPage: number;
  prevPage: number | null;
  totalDocs: number;
  totalPages: number;
}

export const QUERY_CLIENT_LIST = gql`
  query getCustomersTabInfo($project_id: ID!, $params: PaginatedClientsParams!, $filters: ClientsManagerFilters) {
    getCustomersTabInfo(project_id: $project_id, params: $params, filters: $filters) {
      data {
        _id
        firstName
        lastName
        email
        tel
        appointee {
          id
          firstName
          lastName
          email
          role
          isDisabled
        }
        createdOn
        updatedOn
      }
      paginationInfo {
        totalDocs
        totalPages
        page
        perPage
        prevPage
        nextPage
      }
    }
  }
`;

export interface ResponseQueryClientList {
  getCustomersTabInfo: {
    data: Array<Client>;
    paginationInfo: Pagination;
  };
}

export const QUERY_CLIENT_LIST_MYHOME = gql`
  query getMyHomeTabInfo($project_id: ID!, $params: PaginatedClientsParams!, $filters: ClientsManagerFilters) {
    getMyHomeTabInfo(project_id: $project_id, params: $params, filters: $filters) {
      data {
        confirmed
        dateOperationFailed
        firstName
        lastName
        lastAccess
        myhomeStatus
        myhome_version
        reserved
        user
      }
      paginationInfo {
        totalDocs
        totalPages
        page
        perPage
        prevPage
        nextPage
      }
    }
  }
`;

export interface ResponseQueryClientListMyHome {
  getMyHomeTabInfo: {
    data: Array<ClientMyHome>;
    paginationInfo: Pagination;
  };
}

export const QUERY_CLIENT_LIST_CRM = gql`
  query getCRMTabInfo($project_id: ID!, $params: PaginatedClientsParams!, $filters: ClientsManagerFilters) {
    getCRMTabInfo(project_id: $project_id, params: $params, filters: $filters) {
      data {
        _id
        firstName
        lastName
        actions {
          _id
          actionName
          actionDate
          category
          vendor
          quote
          note
          deleted
          createdOn
          eventId
        }
        additionalInfo {
          tag
        }
        appointee {
          id
          firstName
          lastName
          email
          role
          isDisabled
        }
        createdOn
        updatedOn
      }
      paginationInfo {
        totalDocs
        totalPages
        page
        perPage
        prevPage
        nextPage
      }
    }
  }
`;

export interface ResponseQueryClientListCRM {
  getCRMTabInfo: {
    data: Array<ClientCRM>;
    paginationInfo: Pagination;
  };
}
