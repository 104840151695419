import Cookies from 'js-cookie';

import { setConfig } from '../config';
import { PageClients } from './components/Page';
import { ProviderI18n } from '../i18n/i18.components';
import { TLocale } from '../i18n/i18n.declarations';
import { ReduxProvider } from './redux/Provider';
import translationsEN from './translations/en-GB/translation.json';

export interface Props {
  project_id: string;
  showToolbar?: boolean;
  languages: Array<TLocale>;
}

setConfig({
  APOLLO_URI: process.env.REACT_APP_APOLLO_URI || '',
  BUCKET_BASEURL: process.env.REACT_APP_BUCKET_BASEURL || '',
  API_URI: '',
  token: Cookies.get('jwt') || '',
});

export const ModuleClients = ({ project_id, showToolbar = false, languages }: Props) => {
  return (
    <ReduxProvider>
      <ProviderI18n project_id={project_id} languages={languages} translations={translationsEN}>
        <PageClients project_id={project_id} showToolbar={showToolbar} />
      </ProviderI18n>
    </ReduxProvider>
  );
};
