import { createSlice } from '@reduxjs/toolkit';

import { thunkClientList, thunkClientListCRM, thunkClientListMyHome } from './thunks';
import { Client, ClientCRM, ClientMyHome, TableTab } from '../declarations/common';

export const sliceClienti = createSlice({
  initialState: {} as {
    clients: Array<Client | ClientMyHome | ClientCRM>;
    loading?: boolean;
    projectId?: string;
    filters?: {
      totalDocs: number;
      totalPages: number;
      page: number;
      perPage: number;
      prevPage: number | null;
      nextPage: number | null;
    };
    sortOrder?: 1 | -1;
    sortField?: 'CREATED_ON' | 'UPDATED_ON' | 'EMAIL' | 'FULL_NAME' | 'FIRST_NAME' | 'LAST_NAME' | 'PHONE';
    selectedTab: TableTab;
  },
  name: 'clients',
  reducers: {
    setPage: (state, action) => {
      if (state.filters) state.filters.page = action.payload;
    },
    setPageSize: (state, action) => {
      if (state.filters) state.filters.perPage = action.payload;
    },
    setSortDirection: (state, action) => {
      state.sortOrder = action.payload;
    },
    setSortField: (state, action) => {
      state.sortField = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
  },
  extraReducers: (builder) => {
    // Contacts tab
    builder.addCase(thunkClientList.pending, (state, action) => {
      state.projectId = action.meta.arg.project_id;
      state.loading = true;
    });
    builder.addCase(thunkClientList.fulfilled, (state, action) => {
      delete state.loading;
      state.clients = action.payload.data;
      state.filters = action.payload.paginationInfo;
    });
    builder.addCase(thunkClientList.rejected, (state) => {
      delete state.loading;
    });
    // MyHome tab
    builder.addCase(thunkClientListMyHome.pending, (state, action) => {
      state.projectId = action.meta.arg.project_id;
      state.loading = true;
    });
    builder.addCase(thunkClientListMyHome.fulfilled, (state, action) => {
      delete state.loading;
      state.clients = action.payload.data;
      state.filters = action.payload.paginationInfo;
    });
    builder.addCase(thunkClientListMyHome.rejected, (state) => {
      delete state.loading;
    });
    // CRM tab
    builder.addCase(thunkClientListCRM.pending, (state, action) => {
      state.projectId = action.meta.arg.project_id;
      state.loading = true;
    });
    builder.addCase(thunkClientListCRM.fulfilled, (state, action) => {
      delete state.loading;
      state.clients = action.payload.data;
      state.filters = action.payload.paginationInfo;
    });
    builder.addCase(thunkClientListCRM.rejected, (state) => {
      delete state.loading;
    });
  },
});
