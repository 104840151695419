import React from 'react';

import { CircularProgress } from '@material-ui/core';
import { Trans, useTranslation } from '@tecma/i18n';
import { observer } from 'mobx-react';

import useNewsletter from 'customHooks/useNewsletter';

import CrmButton from 'components/common/CrmButton';
import CrmInputCheckbox from 'components/common/CrmInputCheckbox';
import CrmInputRadioButton from 'components/common/CrmInputRadioButton';
import CrmSimpleModal from 'components/common/CrmSimpleModal';

import './newsletter-modal.scss';

const NewsletterModal = () => {
  const { t } = useTranslation();
  const {
    isOpenModal,
    subscriptionValue,
    privacyPolicyValue,
    showErrorPrivacy,
    isLoading,
    isDisabledConfirmAction,
    isDisabledPrivacy,
    privacyDocumentHref,
    onClickConfirmAction,
    onClickCancelAction,
    onChangeSubscription,
    onChangePrivacy,
  } = useNewsletter();

  return (
    <CrmSimpleModal
      open={isOpenModal}
      title={t('newsletter.title')}
      extraClassesTitle='newsletter-modal__title'
      cancelLabel={t('general.cancel')}
      dialogClasses={{ paper: 'newsletter-modal' }}
      cancelAction={onClickCancelAction}
      onClose={onClickCancelAction}
      customConfirmButton={
        <CrmButton
          onClick={onClickConfirmAction}
          disabled={isDisabledConfirmAction}
          className='modal-action-button newsletter-modal__save-button'
        >
          {isLoading ? <CircularProgress color='secondary' className='newsletter-modal__loader' /> : t('general.save')}
        </CrmButton>
      }
    >
      <div className='newsletter-modal__content'>
        <span className='newsletter-modal__description'>
          <Trans i18nKey='newsletter.description' components={{ br: <br />, b: <strong /> }} />
        </span>
        <CrmInputRadioButton
          onChange={onChangeSubscription}
          value={subscriptionValue}
          canResetChoice={false}
          extraClass='newsletter-modal__radio-buttons'
          options={[
            { value: true, label: t('label.si', { defaultValue: 'Yes' }) },
            { value: false, label: t('label.no', { defaultValue: 'No' }) },
          ]}
        />
        <div className='newsletter-modal__agreement'>
          <CrmInputCheckbox
            extraClass='newsletter-modal__privacy'
            error={showErrorPrivacy}
            disabled={isDisabledPrivacy}
            onChange={onChangePrivacy}
            required
            value={privacyPolicyValue}
          />
          <span className='newsletter-modal__agreement__text'>
            <Trans
              i18nKey='newsletter.policy-agreement'
              components={{
                1: (
                  <a rel='noreferrer' target='_blank' href={privacyDocumentHref}>
                    {t('newsletter.privacy-policy', { defaultValue: 'Privacy Policy' })}
                  </a>
                ),
              }}
            />
          </span>
        </div>
        {showErrorPrivacy && <span className='newsletter-modal__error'>{t('newsletter.privacy-error')}</span>}
      </div>
    </CrmSimpleModal>
  );
};

export default observer(NewsletterModal);
