import styled from '@emotion/styled';

export const PageHeaderActions = styled.div<{ height: number }>(({ height }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  position: 'absolute',
  top: height + 46,
  right: 0,
  '@media (max-width: 599px)': {
    top: height + 52,
    right: 16,
  },
}));
