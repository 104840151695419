import { memo, useEffect } from 'react';

import { TableMaterial as TecmaTable, Button, Spinner } from '@tecma/ds';
import { useTranslation } from '@tecma/i18n';

import { columnsTableClients } from '../../constants/config';
import { useModuleClients } from '../../hooks/useModuleClients';

import './Table.style.scss';

interface Props {
  project_id: string;
}

// FIXME: sistemare traduzioni (DONE), sistemare traduzione TablePagination che non cambia al cambio lingua

export const Table = memo(({ project_id }: Props) => {
  const { t } = useTranslation();
  const { clients, loading, getClients, onRefresh, onChangePage, onChangeRowsPerPage, onSortChange, page, pageSize, totalDocs } =
    useModuleClients(project_id);
  const showPagination = clients.length > 0;
  const totalPages = Math.ceil(totalDocs / pageSize);
  const columns = columnsTableClients(t);

  useEffect(() => {
    getClients({ page, perPage: pageSize, searchText: sessionStorage.getItem('searchText') || '' });
  }, []);

  return (
    <TecmaTable
      className='table-clients'
      totalPages={totalPages}
      title={t('table.header.title')}
      isLoading={loading}
      // @ts-ignore
      columns={columns}
      data={clients}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSortChange={onSortChange}
      page={page - 1}
      pageSize={pageSize}
      pageSizeOptions={[5, 10, 25]}
      pageSizeLabel={t('table.rows')}
      emptyDataLabel={t('table.clientNotFound')}
      showPagination={showPagination}
      toolbarActions={
        <Button
          id='table-clients-refresh-button'
          data-testid='table-clients-refresh-button'
          onClick={onRefresh}
          outlined
          iconName={!loading ? 'refresh' : ''}
        >
          {loading && <Spinner size='small' type='dotted-circle' />} {t('table.header.refresh')}
        </Button>
      }
    />
  );
});
