import { Button, Tooltip } from '@tecma/ds';
import { useHistory } from 'react-router-dom';

import TextWithIcon from 'components/specific/TextWithIcon';

import { clientDetailRoute } from '../../../routes/Routes';
import { useStore } from '../../../store/storeUtils';
import { utilityFormatDate } from '../../helpers/formatDate';
import { Client } from '../declarations/common';

import WarningIcon from 'images/assets/warning.svg';

import type { TFunction } from '@tecma/i18n';

// #region CELL USER COMPONENT //NOTE: This component is should be replaced by a Link
interface Props {
  client: Client;
}
const ItemUser = ({ client }: Props) => {
  const store = useStore();
  const history = useHistory();

  const handleClick = () => {
    // @ts-ignore
    store.setClientId(client._id);
    const destination = clientDetailRoute.to.replace(':id', client._id);
    store.setRealLocation(destination);
    history.push(destination);
  };

  return (
    <Button
      onClick={handleClick}
      link
      style={{ textAlign: 'left', height: 'auto', padding: 0 }}
    >{`${client.firstName || ''} ${client.lastName || ''}`}</Button>
  );
};
const ItemEmail = ({ client }: Props) => {
  return (
    <Tooltip disableFocusListener title={client.email} placement='top-start'>
      <span>{client.email}</span>
    </Tooltip>
  );
};
const ItemAppointee = ({ client }: Props) => (
  <TextWithIcon
    text={client.appointee ? `${client.appointee?.firstName} ${client.appointee?.lastName}` : '-'}
    disabled={client.appointee?.isDisabled}
    showIcon={client.appointee?.isDisabled}
    icon={WarningIcon}
  />
);

// #endregion

export const columnsTableClients = (t: TFunction) => [
  {
    field: 'user',
    title: t('table.column.user'),
    render: (client: Client) => <ItemUser client={client} />,
  },
  {
    field: 'email',
    title: t('table.column.email'),
    render: (client: Client) => <ItemEmail client={client} />,
  },
  {
    field: 'tel',
    title: t('table.column.tel'),
    render: (client: Client) => `${client.tel}`,
    sort: false,
  },
  {
    field: 'createdOn',
    title: t('table.column.createdOn'),
    render: (client: Client) => <div>{`${utilityFormatDate(client.createdOn)}`}</div>,
  },
  {
    field: 'updatedOn',
    title: t('table.column.updatedOn'),
    render: (client: Client) => <div>{`${utilityFormatDate(client.updatedOn)}`}</div>,
  },
  {
    field: 'appointee',
    title: t('table.column.appointee'),
    render: (client: Client) => <ItemAppointee client={client} />,
    sort: false,
  },
];
