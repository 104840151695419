import React from 'react';

import { useMutation } from '@apollo/client';
import { Menu, Button } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from '@tecma/i18n';
import { observer } from 'mobx-react';

import Client from 'client/Client';
import { useModuleClients } from 'customHooks/useModuleClients';
import { useStore } from 'store/storeUtils';

import CrmLoader from 'components/common/CrmLoader';
import CrmSimpleModal from 'components/common/CrmSimpleModal';
import CrmSvgLoader from 'components/common/CrmSvgLoader';
import NewsletterModal from 'components/specific/NewsletterModal';

import { ENABLE_NEWSLETTER_POLICY } from 'constants/PagePermission';

import UserLogo from 'images/assets/user_white.svg';

import 'styles/avatar.scss';

const CrmAvatar = observer(() => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [setTwoFA, { loading }] = useMutation(Client.UPDATE_TwoFA);

  const store = useStore();

  const [value, setValue] = React.useState(store.loggedUser.TwoFA);
  const isEnabledNewsletter = ENABLE_NEWSLETTER_POLICY.includes(store.loggedUser.role);
  const { onLogout } = useModuleClients();

  const openMenu = (e) => {
    if (!anchorEl) {
      setAnchorEl(e.target);
    }
    store.setAvatarMenu(true);
  };

  const close = () => {
    store.setAvatarMenu(false);
  };

  const logOutHandler = async () => {
    onLogout();
    store.forceLogout(true); // prima passa per il componente "LogoutListener" per la pulizia dello store e poi avverrà il reindirizzamento
  };

  const openModalTwoFA = () => {
    store.setOpenTwoFAModal(true);
  };

  const openNewsletterModal = () => {
    store.setOpenNewsletterModal(true);
  };

  const ModalTwoFA = () => {
    setTwoFA(Client.UPDATE_TwoFA_DEFAULT_OPTIONS(store.projectId, store.loggedUser.id, !value))
      .then(() => {
        store.setLoggedUserTwoFA(!value);
        setValue(!value);
      })
      .catch(() => {
        store.setSnackbarError(true, t('snackbar.errorTwoFA'));
      });
  };

  const handleChange = () => {
    ModalTwoFA();
  };

  return (
    <>
      <div className='avatar-wrapper'>
        <Button aria-controls='simple-menu' className='user-button' onClick={openMenu} disableRipple={true}>
          <CrmSvgLoader data={UserLogo} alt='User' className='user-logo' />
          <div className='logged-user-name'>{store.loggedUserFullname.toLowerCase()}</div>
        </Button>
        {store.avatarMenu && (
          <Menu
            id='menu2'
            anchorEl={anchorEl}
            keepMounted
            open={store.avatarMenu}
            onClose={close}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            classes={{
              paper: 'no-border user-option-selection',
            }}
          >
            <MenuItem
              classes={{
                root: 'inline-block',
              }}
              onClick={openModalTwoFA}
            >
              {t('otp.otp')}
              {value ? t('otp.attivato') : t('otp.disattivato')}
            </MenuItem>
            {isEnabledNewsletter && (
              <MenuItem
                classes={{
                  root: 'inline-block',
                }}
                onClick={openNewsletterModal}
              >
                {t('newsletter.header-button')}
              </MenuItem>
            )}
            <Divider variant='middle' />
            <MenuItem
              classes={{
                root: 'inline-block',
              }}
              onClick={logOutHandler}
            >
              {t('menu.logout')}
            </MenuItem>
          </Menu>
        )}
      </div>
      <CrmSimpleModal
        open={store.openTwoFAModal}
        title={!value ? t('otp.attivazione') : t('otp.disattivazione')}
        buttonLabel={t('general.confirm')}
        extraClassesTitle='otp-modal-title'
        cancelLabel={t('general.cancel')}
        dialogClasses={{ paper: 'modal-custom-container modal-custom-otp-container' }}
        actionToDo={() => handleChange()}
        onClose={() => store.setOpenTwoFAModal(false)}
        cancelAction={() => store.setOpenTwoFAModal(false)}
      >
        <div
          className='otp-modal-text'
          dangerouslySetInnerHTML={{ __html: t('otp.spiegazione', { interpolation: { escapeValue: false } }) }}
        ></div>
      </CrmSimpleModal>
      <NewsletterModal />
      {loading && <CrmLoader customClass='fixedPosition' loading={loading} hasBackdrop={false} z />}
    </>
  );
});

export default CrmAvatar;
