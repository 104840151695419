import styled from '@emotion/styled';

export const PageWrapper = styled.div({
  padding: '2.5rem .25rem 0 1.25rem',
  display: 'flex',
  flexDirection: 'column',
  gap: '1.5rem',
  '@media (max-width: 599px)': {
    padding: '2.5rem 1rem 0',
  },
});
